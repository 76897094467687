:root {
    --search-root-backgroundColor: var(--g-color-system-mid);
    --search-tabs-section-backgroundColor: var(--c-color-transparent);
    --page-header-nav: var(--g-color-system-light);
    --page-header-nav-links: var(--g-color-system-dark);
    --page-header-nav-active-colour: var(--g-color-brand-primary);
    --search-results-summary-text-colour: var(--g-color-system-dark);
    --search-players-background-colour: var(--player-list-background-colour);
    --search-players-heading-colour: var(--widgetHeader-title-color);
    --search-content-section-backgroundColor: var(--c-color-transparent);
    --search-content-heading-colour: var(--widgetHeader-title-color);
    --search-content-load-more-background-colour: var(
        --g-button-secondary-background
    );
    --search-content-load-more-border-colour: var(
        --g-button-secondary-border-color
    );
    --search-content-load-more-text-colour: var(--g-button-secondary-color);
}

.w-search {
    min-height: 56rem;
    background-color: var(--search-root-backgroundColor);

    &.no-players {
        // stylelint-disable selector-max-attribute

        .page-nav__link[data-value='PLAYERS'],
        .content-slider {
            display: none;
        }
        // stylelint-enable
    }

    &__tabs-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: 5.6rem;
        padding-bottom: 2.4rem;
        background-color: var(--search-tabs-section-backgroundColor);
    }

    .search-tabs {
        margin: 0 auto 2.4rem;
    }

    &__results-summary {
        height: 2.3rem;
        font-size: 2rem;
        font-weight: $bold;
        color: var(--search-results-summary-text-colour);
    }

    &__summary-bottom {
        display: none;
        margin-top: 0;
        align-self: center;
    }

    .o-widget-header {
        align-items: center;
    }

    .content-slider {
        overflow: visible;
        background-color: var(--search-players-background-colour);

        .o-widget-header__title {
            color: var(--search-players-heading-colour);
        }
    }

    &__content-results {
        background-color: var(--search-content-section-backgroundColor);

        .o-widget-header__title {
            color: var(--search-content-heading-colour);
        }
    }

    &__load-more-container {
        display: flex;
        justify-content: center;
        margin-top: 1.6rem;

        // Load more CTA overrides
        .o-cta {
            min-width: 24rem;
            text-transform: uppercase;
            color: var(--search-content-load-more-text-colour);
            background: var(--search-content-load-more-background-colour);
            border-color: var(--search-content-load-more-border-colour);

            @include hover {
                color: var(--search-content-load-more-text-colour);
                background: var(--search-content-load-more-background-colour);
                border-color: var(--search-content-load-more-border-colour);
            }
        }
    }

    &__no-results {
        margin: 2.4rem auto;
        font-size: 2rem;
        text-align: center;
    }

    &__loader {
        display: flex;
        justify-content: center;
        margin-top: 8rem;
        padding-bottom: 6.4rem;
    }

    &__loader-icon {
        display: block;
        width: 6.4rem;
        height: 6.4rem;
        animation: spin 1s linear infinite;
        fill: #fff;
        opacity: 0.6;
    }

    // CONTENT META
    .o-content-meta {
        &__item {
            &::before {
                background: var(--contentListing-text-color);
            }

            &--text {
                color: var(--contentListing-text-color);
            }

            &--tag {
                color: var(--contentListing-contentMetaTag-color);
                background: var(
                    --contentListing-contentMetaTag-backgroundColor
                );
            }
        }
    }
}

@media (max-width: $break-phablet) {
    .w-search {
        &__tabs-wrapper {
            padding-top: 2.4rem;
            padding-bottom: 1.2rem;
        }

        .search-tabs {
            margin-bottom: 3.2rem;
        }

        &__results-summary {
            margin-top: 0;
            font-size: 1.6rem;
        }

        .o-widget-header {
            margin-bottom: 1.6rem;
            align-items: center;
        }

        &__results-list {
            margin-top: 1.6rem;
        }

        &__loader {
            margin-top: 5.4rem;
        }

        &__loader-icon {
            width: 4.8rem;
            height: 4.8rem;
        }
    }
}
