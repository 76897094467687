// Styles for: Share widget
// Notes: Share widget style for all devices
// stylelint-disable selector-max-specificity

// CONFIG

:root {
    --share-root-backgroundColor: var(--g-widget-background);
    --share-icon-fill: var(--g-color-system-dark);
    --share-copy-backgroundColor: var(--g-color-system-dark);
    --share-copy-text-color: var(--g-color-system-light);
    --share-label-color: var(--g-color-system-dark);
    --share-border-color: var(--g-color-system-dark);
}

// SHARE

.w-share {
    $this: &;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: var(--share-root-backgroundColor);

    &__buttons {
        display: flex;
        gap: 1.6rem;
    }

    &__button {
        width: 2rem;
        height: 2rem;
    }

    &__icon {
        opacity: 0.5;
        width: 100%;
        height: 100%;
        fill: var(--share-icon-fill);
        transition: all 0.2s;

        @include hover {
            opacity: 1;
            transform: scale(1.1);
        }
    }

    .copy {
        position: relative;

        &__message {
            opacity: 0;
            position: absolute;
            bottom: 3rem;
            left: 50%;
            width: 13rem;
            padding: 0.5rem;
            border-radius: 0.4rem;
            color: var(--share-copy-text-color);
            font-weight: $bold;
            font-size: 1.3rem;
            text-align: center;
            text-transform: uppercase;
            background-color: var(--share-copy-backgroundColor);
            transition: opacity 0.5s;
            transform: translateX(-50%);

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: calc(50% - 0.7rem);
                width: 0;
                height: 0;
                border-top: 0.7rem solid var(--share-copy-backgroundColor);
                border-inline-end: 0.7rem solid transparent;
                border-inline-start: 0.7rem solid transparent;
            }
        }

        &--show-message {
            .copy__message {
                opacity: 1;
            }
        }
    }
}
