// Styles for: Promo featured
// Notes: Promo featured widget style for all devices

// CONFIG

:root {
    --promoFeatured-root-backgroundColor: var(--g-color-system-light);
    --promoFeatured-root-borderRadius: var(--g-layout-border-radius);
    --promoFeatured-text-color: var(--g-typography-color-widget-component);
    --promoFeatured-gradient-color: var(--g-color-system-light);
    --promoFeatured-brandBar-background: var(--g-color-gradient-1);
}

// Renders a gradient overlay over media image with the given colour
// This was necessary as we need to use theme css variable here which can't be passed into rgba()
@mixin media-overlay($colour) {
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $level-one;
    }

    &::before {
        background: $colour;
        opacity: 0.65;
    }

    &::after {
        background: linear-gradient(
            to right,
            $colour 0%,
            transparent 30%,
            transparent 70%,
            $colour 100%
        );
        opacity: 0.93;
    }
}

// PROMO FEATURED

.w-promo-featured {
    $this: &;
    position: relative;
    width: 100%;
    background: var(--promoFeatured-root-backgroundColor);

    &__brand-bar {
        z-index: $level-one;
        position: absolute;
        inset: 0;
        height: 1.6rem;
        background: var(--promoFeatured-brandBar-background);
        width: 100%;
    }

    &__inner {
        position: relative;
        border-radius: var(--promoFeatured-root-borderRadius);
        overflow: hidden;
        box-shadow: var(--g-layout-box-shadow);
    }

    &--full-width {
        #{$this}__wrapper {
            max-width: $wrapper-max-width;
            padding: 0;
        }

        #{$this}__inner {
            overflow: visible;
        }
    }

    &--left #{$this} {
        &__inner {
            width: 100%;
        }

        &__content {
            margin: 0;
            text-align: unset;
            align-items: flex-start;
        }

        &__cta-container {
            justify-content: flex-start;
        }
    }

    &__wrapper {
        position: relative;
        height: 100%;
    }

    &__content-wrapper {
        display: flex;
        position: relative;
        height: 100%;
        padding: 0;
        z-index: $level-two;
    }

    &__media {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $black;
        @include media-overlay(var(--promoFeatured-gradient-color));
    }

    &__content {
        z-index: $level-one;
        position: relative;
        margin: 0 auto;
        max-width: 80rem;
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 5.6rem;
        @media (max-width: $break-tablet) {
            padding: 2.4rem;
        }

        @each $size,
            $value
                in (
                    small: (
                        3.2rem,
                        1.6rem
                    ),
                    medium: (
                        5.6rem,
                        2.4rem
                    ),
                    large: (
                        8rem,
                        3.2rem
                    )
                )
        {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: nth($value, 1);

                @media (max-width: $break-tablet) {
                    padding: nth($value, 2);
                }
            }
        }
    }

    &__label {
        @include heading-XS;
        font-size: 1.6rem;
        margin-bottom: $spacing-l;
        color: var(--promoFeatured-text-color);
    }

    &__title {
        @include heading-XL;
        width: 100%;
        margin-bottom: $spacing-l;
        color: var(--promoFeatured-text-color);
    }

    &__description {
        @include body-large;
        width: 100%;
        color: var(--promoFeatured-text-color);
        max-width: 64rem;
    }

    &__cta-container {
        margin-top: 4rem;
        display: flex;
        justify-content: center;
        gap: 1.6rem;

        .o-cta {
            min-width: 18rem;
            max-width: 50%;
        }
    }
}

@media (max-width: $break-tablet) {
    .w-promo-featured {
        &__brand-bar {
            height: 1.2rem;
        }

        &__icon {
            margin-bottom: $spacing-xl;
        }

        &__title {
            margin-bottom: $spacing-m;
        }

        &__description {
            @include body-medium;
            font-size: 1.8rem;
            line-height: 2.8rem;
        }
    }
}

@media (max-width: $break-phablet) {
    .w-promo-featured {
        $this: &;

        &__inner {
            &:has(#{$this}__brand-bar) {
                padding-top: 0.8rem;
            }
        }

        &__label {
            font-size: 1.2rem;
        }

        &__cta-container {
            flex-direction: column;
            margin-top: $spacing-l;

            .o-cta {
                width: 100%;
                min-width: 100%;
            }
        }
    }
}
