// Styles for: Content hero
// Notes: Content hero widget style for all devices

// CONFIG

:root {
    --contentHero-root-backgroundColor: var(--g-widget-background);
    --contentHero-mediaPod-boxShadow: var(--largeMediaPod-root-boxShadow);
    --contentHero-mediaPod-border: var(--largeMediaPod-root-border);
    --content-hero-button-color: var(--g-button-ghost-color);
    --contentHero-mediaPod-backgroundColor: var(--g-component-background);
    --contentHero-mediaPod-text-color: var(
        --g-typography-color-widget-component
    );
    --contentHero-contentMetaTag-backgroundColor: var(
        --contentMeta-tag-backgroundColor
    );
    --contentHero-contentMetaTag-color: var(--contentMeta-tag-color);
}

// CONTENT HERO

.w-content-hero {
    $this: &;
    position: relative;
    background-color: var(--contentHero-root-backgroundColor);

    // IF HERO DIVIDE TOP PADDING SO IT
    // IS CLOSER TO THE NAVIGATON

    &--is-hero {
        padding-top: calc(var(--g-layout-widget-spacing) / 2);
    }

    &__container {
        display: flex;
    }

    &__wrapper {
        @include wrapper;
    }

    &__wrapper--wide {
        max-width: $wrapper-max-width;
    }

    &__featured {
        width: 100%;
        max-width: 60rem;
    }

    &__featured--wide {
        max-width: 45%;
    }

    &__supporting {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &:not(.u-no-padding) {
        #{$this}__container,
        #{$this}__supporting {
            gap: 2.4rem;
            @media (max-width: $break-tablet) {
                gap: 1.2rem;
            }

            @each $size,
                $values
                    in (
                        extra-small: (
                            0.2rem,
                            0.1rem
                        ),
                        small: (
                            0.8rem,
                            0.4rem
                        ),
                        medium: (
                            1.6rem,
                            0.8rem
                        ),
                        large: (
                            2.4rem,
                            1.2rem
                        )
                    )
            {
                body[data-theme-widget-card-outer-spacing='#{$size}'] & {
                    gap: nth($values, 1);

                    @media (max-width: $break-tablet) {
                        gap: nth($values, 2);
                    }
                }
            }
        }
    }

    &__supporting-item {
        height: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }

    // MEDIA POD

    .o-large-media-pod,
    .o-wide-small-media-pod {
        border: var(--contentHero-mediaPod-border);
        background-color: var(--contentHero-mediaPod-backgroundColor);
        box-shadow: var(--contentHero-mediaPod-boxShadow);

        &__heading {
            color: var(--contentHero-mediaPod-text-color);
        }

        &__summary {
            color: var(--contentHero-mediaPod-text-color);
        }

        .o-arrow-link {
            color: var(--content-hero-button-color);

            svg {
                fill: var(--content-hero-button-color);
            }
        }
    }

    .o-wide-small-media-pod {
        // TRUNCATE TEXT TO
        // ONE LNE

        &__summary {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    // CONTENT META

    .o-content-meta {
        &__item {
            &::before {
                background: var(--contentHero-mediaPod-text-color);
            }

            &--text {
                color: var(--contentHero-mediaPod-text-color);
            }

            &--tag {
                color: var(--contentHero-contentMetaTag-color);
                background: var(--contentHero-contentMetaTag-backgroundColor);
            }
        }
    }

    @media (max-width: 1400px) {
        &__container {
            flex-direction: column;
        }

        &__featured {
            max-width: 100%;
            padding-inline-end: 0;
        }

        &__supporting {
            display: flex;
            flex-flow: row wrap;
            clear: both;
        }

        &__supporting-item {
            height: auto;
            display: flex;
            flex: 1;
            margin-bottom: 0;
        }

        .o-wide-small-media-pod {
            // RESET TRUNCATION

            &__summary {
                white-space: pre-wrap;
            }
        }
    }

    @media (max-width: $break-tablet) {
        &--is-hero {
            padding-top: calc(var(--g-layout-widget-spacing) / 2);
        }

        &__supporting-item {
            width: 100%;
        }
    }

    @media (max-width: $break-phablet) {
        &--is-hero {
            padding-top: calc(var(--g-layout-widget-spacing) / 2);
        }
    }
}
