// Styles for: Embeddable table widget
// Notes: Embeddable table widget style for all devices

// EMBEDDABLE TABLE

.w-embeddable-table {
    --embeddableTable-text-color: var(--g-embed-text-color);
    --embeddableTable-background-color: var(--g-embed-background-color);
    --embeddableTable-caption-color: var(--g-embed-caption-color);

    $table-large-width: 80rem;
    $table-in-line-width: 64rem;
    margin: 0 auto;
    position: relative;
    color: var(--embeddableTable-text-color);
    padding: calc(var(--g-style-widget-spacing) / 2) 0;

    &--full-width {
        max-width: 100%;
    }

    &--large {
        max-width: $table-large-width;
    }

    &--in-line {
        max-width: $table-in-line-width;
    }

    &__caption {
        @include meta-2;
        display: block;
        opacity: 0.5;
        width: 100%;
        max-width: $table-in-line-width;
        margin: -0.6rem auto 0;
        padding: 1.6rem 1.2rem;
        color: var(--embeddableTable-caption-color);
        text-align: center;
    }

    // OVERRIDES

    /* stylelint-disable declaration-no-important */
    .table-scrollable {
        margin-bottom: 0;

        table {
            background-color: var(--embeddableTable-background-color);
        }

        th {
            color: var(--embeddableTable-text-color) !important;
        }

        tfoot {
            background-color: var(--embeddableTable-text-color) !important;
            color: var(--embeddableTable-background-color) !important;

            /* stylelint-disable-next-line selector-max-type */
            tr th,
            tr td {
                background-color: var(--embeddableTable-text-color) !important;
                color: var(--embeddableTable-background-color) !important;
            }
        }
    }
    /* stylelint-enable declaration-no-important */
}
