.table-scrollable {
    margin-bottom: 2.4rem;
    display: block;
    width: 100%;
    overflow-x: auto;
    box-shadow: 0.2rem 0.4rem 1rem rgba($black, 0.2);

    &::-webkit-scrollbar {
        height: 0.4rem;
        background: $color-mid;
    }

    &::-webkit-scrollbar-thumb {
        color: var(--g-color-brand-primary);
        background: var(--g-color-brand-primary);
    }
}

// butt table scrollable to edge when in article

@media (max-width: $break-mobile) {
    .table-scrollable {
        width: calc(100% + 1.2rem);
    }
}

/* stylelint-disable selector-max-type */
.table-scrollable table,
table {
    @include body-medium;
    margin-bottom: 1.2rem;
    background: $white;
    table-layout: auto;
    width: 100%;

    a {
        font-size: 1.4rem;
    }

    caption {
        @include meta-2;
        opacity: 0.5;
        max-width: 64rem;
        margin: 0 auto;
        padding: 1.6rem 1.2rem;
        color: $color-dark;
        text-align: center;
    }

    tbody {
        tr:nth-child(even) {
            background-color: rgba($color-dark, 0.05);
        }
    }

    thead {
        border-bottom: 0.4rem solid var(--g-color-brand-primary);
        background-color: rgba($color-mid, 0.1);

        tr th,
        tr td {
            @include heading-S;
            padding: 1.4rem 1.6rem;
            color: var(--g-color-system-dark);

            &::after {
                display: none;
            }

            @media (max-width: $break-mobile) {
                padding: 0.6rem 1.6rem 0.7rem;
            }
        }
    }

    tfoot {
        background: $color-dark;

        tr th,
        tr td {
            padding: 1.2rem 1.6rem;
            color: $white;
            background-color: $color-dark;
            border-inline-end: solid 0.1rem $grey-700;

            &::after {
                display: none;
            }

            @media (max-width: $break-mobile) {
                padding: 0.7rem 1.6rem 0.6rem;
            }
        }
    }

    tr th,
    tr td {
        padding: 1.2rem 1.6rem;
        text-align: start;
        border-inline-end: solid 0.1rem rgba($color-primary, 0.2);
        position: relative;

        @media (max-width: $break-mobile) {
            padding: 1.3rem 1.6rem;
        }
    }

    thead tr th:last-child,
    thead tr td:last-child,
    tbody tr th:last-child,
    tbody tr td:last-child,
    tfoot tr th:last-child,
    tfoot tr td:last-child {
        border-inline-end: none;
    }

    thead,
    tfoot,
    tbody {
        tr th,
        tr td {
            display: table-cell;
        }
    }

    tr td {
        display: table-cell;
    }
}

/* stylelint-enable selector-max-type */
