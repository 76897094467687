blockquote {
    padding: 0 2.5rem;
    margin: 0;
    display: block;
    clear: both;
    border-inline-start: 0.7rem solid var(--article-text-color);
    margin-bottom: 3rem;

    @media (max-width: $break-mobile) {
        padding: 0 $spacing-xs;
        margin-left: -0.7rem;
    }

    /* stylelint-disable-next-line selector-max-type */
    p {
        @include heading-S;
        margin-bottom: 0;
        color: var(--article-text-color);
    }
}
