// Styles for: Embeddable video widget
// Notes: Embeddable video widget style for all devices

// EMBEDDABLE VIDEO

.w-embeddable-video {
    $this: &;

    --embeddableVideo-caption-color: var(--g-embed-caption-color);

    $video-large-width: 80rem;
    $video-in-line-width: 64rem;
    padding: calc(var(--g-style-widget-spacing) / 2) 0;

    &__inner {
        margin: 0 auto;
    }

    &--large {
        #{$this}__inner {
            max-width: $video-large-width;
        }
    }

    &--in-line {
        #{$this}__inner {
            max-width: $video-in-line-width;
        }
    }

    &__video-container {
        position: relative;

        &::before {
            content: '';
            display: block;
            padding-bottom: calc((9 / 16) * 100%);
        }
    }

    &__caption {
        @include meta-2;
        opacity: 0.5;
        max-width: $video-in-line-width;
        margin: 0 auto;
        padding: 1.6rem 1.2rem;
        color: var(--embeddableVideo-caption-color);
        text-align: center;
    }
}
