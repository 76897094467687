$event-constrained-width: 64rem;

.event-page {
    $this: &;

    --eventPage-backgroundColor: var(--g-website-background);
    --eventPage-heading-colour: var(--g-typography-color-heading);
    --eventPage-text-color: var(--g-typography-color-paragraph);
    background: var(--eventPage-backgroundColor);
    min-height: 20rem;

    &__error {
        margin: $spacing-l 0;
    }

    &__loader {
        display: flex;
        justify-content: center;
        margin-top: 8rem;
        padding-bottom: 6.4rem;
    }

    &__loader-icon {
        display: block;
        width: 6.4rem;
        height: 6.4rem;
        animation: spin 1s linear infinite;
        opacity: 0.6;
    }

    &__wrapper {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        position: relative;
    }

    &__hero {
        width: 100%;
        height: 35rem;
        overflow: hidden;
        position: relative;
        background: var(--eventPage-backgroundColor);
    }

    &__image {
        max-width: $wrapper-width;
        left: 50%;
        transform: translateX(-50%);
    }

    &__header {
        max-width: $event-constrained-width;
        width: 100%;
        padding: $widget-spacing-desktop-medium 0;
    }

    .o-content-meta {
        margin-bottom: $spacing-xs;
    }

    &__heading {
        @include heading-XL;
        margin-bottom: $spacing-m;
        text-transform: capitalize;
        color: var(--eventPage-heading-colour);
    }

    &__summary {
        @include text-copy;
        font-size: var(--c-title-small-desktop);
        line-height: 1.4;
        margin-bottom: 4rem;
        color: var(--eventPage-text-color);
    }

    &__location {
        @include heading-XS;
    }

    &__time,
    &__location,
    &__dates {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0 $spacing-xxs;
        margin-bottom: $spacing-s;
        color: var(--eventPage-text-color);

        &-icon {
            margin: 0;
            padding: 0;
            fill: var(--eventPage-text-color);
        }

        &-text {
            @include heading-XS;
        }
    }

    &__share {
        margin: 4rem 0 $spacing-s;

        &-text {
            font-size: var(--c-text-medium-desktop);
            color: var(--eventPage-text-color);
            opacity: 0.5;
            margin-bottom: 1.6rem;
            text-align: center;
        }

        &--header {
            .w-share {
                justify-content: flex-start;
            }
        }

        &--footer {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .w-share__button {
        .icon {
            fill: var(--eventPage-text-color);
        }
    }

    &__divider {
        width: 100%;
        max-width: $event-constrained-width;
        border-bottom: 0.1rem solid $color-dark;
        opacity: 0.1;
        padding-top: $widget-spacing-tablet-large - $spacing-s;

        &--footer {
            max-width: 100%;
        }
    }

    &__content {
        @include text-copy;
        max-width: $event-constrained-width;
        width: 100%;
        position: relative;

        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6,
        > div,
        > ul,
        > ol,
        > p {
            margin-bottom: $spacing-l;
            color: var(--eventPage-text-color);
        }

        > div,
        > ul,
        > ol,
        > p {
            font-size: var(--c-title-xsmall-desktop);
            line-height: 1.6;
        }

        @extend .w-article-page__content; // stylelint-disable scss/at-extend-no-missing-placeholder
    }

    @media (max-width: $break-phablet) {
        &__hero {
            height: 10rem;
        }

        &__wrapper {
            padding: 0 $spacing-m;
        }

        &__header {
            padding: calc($widget-spacing-mobile-large / 2) 0;
        }

        &__share {
            margin-top: $spacing-l;
        }

        &__heading {
            margin-bottom: $spacing-s;
        }

        &__summary {
            font-size: var(--c-title-medium-mobile);
            margin-bottom: $spacing-l;
        }

        &__tags {
            margin-bottom: $spacing-xs;
        }

        &__spacer {
            padding-top: calc($widget-spacing-tablet-large / 2);
        }

        &__content {
            > div,
            > ul,
            > ol,
            > p {
                font-size: var(--c-text-medium-mobile);
            }

            > h1,
            > h2,
            > h3,
            > h4,
            > h5,
            > h6,
            > div,
            > ul,
            > ol,
            > p {
                margin-bottom: $spacing-m;
            }
        }
    }
}
