// Styles for: Content featured
// Notes: Content featured widget style for all devices

// CONFIG

:root {
    --contentFeatured-root-backgroundColor: var(--g-widget-background);
    --contentFeatured-mediaPodCta-boxShadow-default: var(
        --wideMediaPod-cta-boxShadow-default
    );
    --contentFeatured-mediaPodCta-boxShadow-hover: var(
        --wideMediaPod-cta-boxShadow-hover
    );
    --contentFeatured-mediaPodImageContainer-backgroundColor: var(
        --wideMediaPod-imageContainer-backgroundColor
    );
    --contentFeatured-mediaPod-boxShadow: var(--wideMediaPod-root-boxShadow);
    --contentFeatured-mediaPod-border: var(--wideMediaPod-root-border);
    --contentFeatured-mediaPod-backgroundColor: var(--g-component-background);
    --contentFeatured-text-color: var(--g-typography-color-widget-component);
    --contentFeatured-button-color: var(--c-color-white);
    --contentFeatured-mediaPodBrandBar-background: var(
        --wideMediaPod-brandBar-background
    );
    --contentFeatured-contentMetaTag-backgroundColor: var(
        --contentMeta-tag-backgroundColor
    );
}

.w-content-featured {
    $this: &;
    background-color: var(--contentFeatured-root-backgroundColor);
    position: relative;

    // MEDIA POD

    .o-wide-media-pod {
        background-color: var(--contentFeatured-mediaPod-backgroundColor);
        box-shadow: var(--contentFeatured-mediaPod-boxShadow);
        border: var(--contentFeatured-mediaPod-border);

        &__brand-bar {
            background: var(--contentFeatured-mediaPodBrandBar-background);
        }

        &__heading {
            color: var(--contentFeatured-text-color);
        }

        &__summary {
            color: var(--contentFeatured-text-color);
        }

        &__content {
            padding: 4rem;
            @media (max-width: $break-tablet) {
                padding: 1.6rem;
            }

            @each $size,
                $values
                    in (
                        small: (
                            2.4rem,
                            1.2rem
                        ),
                        medium: (
                            4rem,
                            1.6rem
                        ),
                        large: (
                            5.6rem,
                            2.4rem
                        )
                    )
            {
                body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                    padding: nth($values, 1);

                    @media (max-width: $break-tablet) {
                        padding: nth($values, 2);
                    }
                }
            }
        }
    }

    &--is-wide {
        #{$this}__wrapper {
            max-width: $wrapper-max-width;
            padding: 0;
        }

        .o-wide-media-pod__content {
            padding: 5.6rem;
            @media (max-width: $break-tablet) {
                padding: 2.4rem;
            }

            @each $size,
                $values
                    in (
                        small: (
                            3.2rem,
                            1.6rem
                        ),
                        medium: (
                            5.6rem,
                            2.4rem
                        ),
                        large: (
                            8rem,
                            3.2rem
                        )
                    )
            {
                body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                    padding: nth($values, 1);

                    @media (max-width: $break-tablet) {
                        padding: nth($values, 2);
                    }
                }
            }
        }
    }

    // CONTENT META

    .o-content-meta {
        &__item {
            &::before {
                background: var(--contentFeatured-text-color);
            }

            &--text {
                color: var(--contentFeatured-text-color);
            }
        }
    }
}
