// Styles for: Top bar
// Notes: Top bar widget style for all devices

// CONFIG

:root {
    --top-bar-background-color: var(--g-color-system-mid);
    --top-bar-text-color: var(--g-typography-color-widget-component);
}

// TOP BAR

.w-top-bar {
    $this: &;
    position: relative;
    width: 100%;
    background: var(--top-bar-background-color);
    z-index: $level-three;
    display: flex;
    justify-content: center;

    &__content {
        @include wrapper(false);
        display: flex;
        align-items: center;
        justify-content: center;

        > :not(:first-child) {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 1.6rem;
            position: relative;

            &:first-of-type {
                margin-left: auto;
            }

            // border-left: 1px solid red;
            &::before {
                content: '';
                width: 1px;
                background-color: var(--g-color-system-dark);
                opacity: 0.2;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    // NAVIGATION

    .linked-list {
        display: flex;
        justify-content: center;

        // ITEM

        &__item {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
        }

        // LEVEL 1

        &__dropdown-label,
        &__link {
            width: 100%;
            padding: 1.3rem 1.6rem 1.2rem;
            color: var(--top-bar-text-color);
            font-weight: $bold;
            font-size: 1rem;
            text-align: center;
            text-decoration: none;
            appearance: none;

            @include hover {
                text-decoration: underline;
            }
        }

        // ICONS

        &__button-arrow,
        &__external-arrow {
            position: relative;
            top: 0;
            width: 0.8rem;
            height: 0.8rem;
            fill: var(--top-bar-text-color);
        }

        &__button-arrow {
            display: none;
        }

        // LEVEL 2

        &__items {
            display: none;
        }

        &__dropdown-arrow {
            display: none;
        }
    }

    .language-switcher__button {
        color: var(--top-bar-text-color);

        &-icon {
            fill: var(--top-bar-text-color);
        }
    }

    @media (max-width: $break-desktop) {
        display: none;
    }
}
