// Styles for: Promo listing widget
// Notes: Promo listing widget style for all devices

:root {
    --promoListing-root-backgroundColor: var(--g-widget-background);
    --promoListing-widgetHeaderTitle-color: var(
        --g-typography-color-widget-heading
    );
    --promoListing-brandBar-background: var(--brandBar-background);
    --promoListing-promoPod-backgroundColor: var(
        --promoPod-root-backgroundColor
    );
    --promoListing-promoPodBrandBar-background: var(
        --promoPod-brandBar-background
    );
    --promoListing-promoPod-text-color: var(
        --g-typography-color-widget-component
    );
}

// PROMO LISTING

.w-promo-listing {
    position: relative;
    padding: var(--g-layout-widget-spacing) 0;
    background-color: var(--promoListing-root-backgroundColor);

    // WIDGET HEADER

    .o-widget-header {
        &__title {
            color: var(--promoListing-widgetHeaderTitle-color);
        }

        &__brand-bar {
            background: var(--promoListing-brandBar-background);
        }
    }

    // PROMO POD

    .o-promo-pod {
        background-color: var(--promoListing-promoPod-backgroundColor);

        &__brand-bar {
            background: var(--promoListing-promoPodBrandBar-background);
        }

        &__image-container {
            &::before {
                background-color: var(--promoListing-promoPod-backgroundColor);
            }
        }

        &__heading {
            color: var(--promoListing-promoPod-text-color);
        }

        &__summary {
            color: var(--promoListing-promoPod-text-color);
        }
    }

    &--carousel {
        .o-promo-pod__secondary-image-container {
            min-width: 36.4%;
        }
    }
}
