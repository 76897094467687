.event-calendar {
    --eventCalendar-backgroundColor: var(--c-color-transparent);
    --eventCalendar-date-background: var(--g-color-brand-primary);
    --eventCalendar-date-text: var(--c-color-white);
    --eventCalendar-heading-colour: var(--g-typography-color-widget-heading);
    --eventCalendar-brandBar-background: var(--g-color-brand-accent);
    --eventCalendar-section-heading-text: var(
        --g-typography-color-widget-heading
    );
    --eventCalendar-card-background: var(--g-component-background);
    --eventCalendar-card-brandBar-background: var(--g-color-brand-accent);
    --eventCalendar-text-color: var(--g-typography-color-widget-component);

    $this: &;
    background: var(--eventCalendar-backgroundColor);
    overflow: hidden;

    // WIDGET HEADER
    .o-widget-header__brand-bar {
        background: var(--eventCalendar-brandBar-background);
    }

    &-filters {
        z-index: $level-four;
        position: sticky !important; // stylelint-disable-line declaration-no-important
        background-color: $white;
    }

    &--page {
        padding-top: 4rem;
    }

    &__page-container {
        min-height: 15rem;
        position: relative;
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__no-results {
        @include body-large;
    }

    &__loader-icon {
        display: block;
        width: 6.4rem;
        height: 6.4rem;
        animation: spin 1s linear infinite;
        fill: var(--g-typography-color-widget-heading);
        opacity: 0.6;
    }

    &__filters-container {
        margin-bottom: 4rem;
    }

    &__list-container {
        display: inline-block;
        width: 100%;
        margin-top: calc(var(--g-style-widget-spacing) / 2);
        margin-bottom: calc(var(--g-style-widget-spacing) / 2);
        animation-name: fade-in;
        animation-duration: 0.3s;

        &:last-child {
            padding-bottom: 4rem;
        }
    }

    &__heading {
        color: var(--eventCalendar-heading-colour);

        &--calendar {
            color: var(--eventCalendar-section-heading-text);
            padding: 0 0 4rem;
            @include heading-M;
        }
    }

    &__link {
        text-decoration: none;
        transition: transform 0.15s ease-in-out;
        display: flex;
        flex-flow: column;
        flex: 1;

        @include hover {
            @include arrow-link-parent-hover;
            transform: scale(1.03);

            #{$this}__title {
                text-decoration: underline;
            }
        }
    }

    &__item {
        background: var(--eventCalendar-card-background);
        border-radius: var(--g-layout-border-radius);
        box-shadow: var(--g-layout-box-shadow);
        overflow: hidden;
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__date {
        align-items: center;
        background: var(--eventCalendar-date-background);
        color: var(--eventCalendar-date-text);
        display: flex;
        @include text-misc;
        font-size: 1.2rem;
        font-weight: $bold;
        gap: 0.4rem;
        padding: 0.8rem;

        &-content {
            align-items: center;
            display: flex;
        }
    }

    &__date-icn {
        fill: var(--eventCalendar-date-text);
        margin-inline-end: 0.8rem;
    }

    &__brandbar {
        height: 0.2rem;
        background: var(--eventCalendar-card-brandBar-background);
    }

    &__text-container {
        color: var(--eventCalendar-text-color);
        padding: $spacing-s;
        @media (max-width: $break-tablet) {
            padding: 1.2rem 1.6rem;
        }

        @each $size,
            $value
                in (
                    small: (
                        0.8rem,
                        1.2rem 0.8rem
                    ),
                    medium: (
                        1.2rem,
                        1.6rem 1.2rem
                    ),
                    large: (
                        1.6rem,
                        2rem 1.6rem
                    )
                )
        {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: nth($value, 1);

                @media (max-width: $break-tablet) {
                    padding: nth($value, 2);
                }
            }
        }
    }

    &__image {
        background: var(--g-color-system-dark);
        position: relative;
        padding-top: 25%;
    }

    .o-content-meta {
        margin-bottom: 0.4rem;
    }

    &__title {
        display: block;
        @include heading-S;
        line-height: 2rem;
        margin-bottom: 0.8rem;
    }

    &__description {
        @include text-misc;
        font-size: 1.2rem;
        line-height: 1.25;
        @include truncate-multiline(3, 1.25);
    }

    &__cta-container {
        padding: 0.9rem 1.2rem;
        margin-top: auto;

        &--border {
            border-top: 2px solid $grey-300;
        }
    }

    @media (max-width: $break-phablet) {
        &--page {
            padding-top: calc(var(--g-style-widget-spacing) / 10);
        }

        .o-widget-header {
            margin-top: calc(var(--g-style-widget-spacing) / 4);
        }

        &__heading {
            &--calendar {
                padding: 0 0 1.6rem;
            }
        }

        &__filters-container {
            margin-bottom: 2rem;
        }

        &__list-container {
            margin-top: calc(var(--g-style-widget-spacing) / 4);
            margin-bottom: calc(var(--g-style-widget-spacing) / 4);

            &:last-child {
                padding-bottom: 2rem;
            }
        }

        &__description {
            line-height: 1.5;
            @include truncate-multiline(2, 1.5);
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
