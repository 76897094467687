$desktop-card-height: 32rem;
$mobile-card-height: 51rem;
$desktop-card-height-no-image: 35rem;
$mobile-card-height-no-image: 34rem;
$mobile-card-max-width: 50rem;
$offset-last-desktop: 20rem;
$offset-mid-desktop: calc($offset-last-desktop / 2);
$offset-last-mobile: calc($offset-last-desktop / 5);
$offset-mid-mobile: calc($offset-last-desktop / 10);
$inner-spacing: (
    small: (
        1.6rem,
        1.2rem
    ),
    medium: (
        2.4rem,
        1.6rem
    ),
    large: (
        3.2rem,
        2.4rem
    )
);

// Mixin to generate animation classes based on provided maps
@mixin generate-animation-classes($animations, $selector) {
    @each $class, $props in $animations {
        &.#{$class} {
            @include apply-animation-properties($props, $selector);
        }
    }
}

// Mixin to apply animation properties
@mixin apply-animation-properties($props, $selector) {
    @each $property, $value in $props {
        @if $property == 'z-index' {
            #{$property}: $value;
        } @else {
            #{$selector}__card {
                #{$property}: $value;
            }
        }
    }
}

// Animation maps for mobile and mobile
$animations-mobile: (
    'is-middle': (
        margin-right: $offset-mid-mobile
    ),
    'is-bottom': (
        margin-right: $offset-last-mobile
    ),
    'is-left': (
        transform: translateX(-70%) scale(0.76) rotate(-9deg)
    ),
    'is-right': (
        transform: translateX(70%) scale(1.1) rotate(9deg)
    ),
    'is-last-two:nth-child(2)': (
        margin-right: $offset-last-mobile
    ),
    'is-last': (
        margin-right: $offset-mid-mobile
    )
);

// Animation maps for mobile and desktop
$animations-desktop: (
    'is-active': (
        z-index: 3,
        margin-right: 0
    ),
    'is-middle': (
        z-index: 2,
        margin-right: $offset-mid-desktop,
        transform: scale(0.92)
    ),
    'is-bottom': (
        z-index: 1,
        margin-right: $offset-last-desktop,
        transform: scale(0.84)
    ),
    'is-left': (
        z-index: 0,
        transform: translateX(-30%) scale(0.76)
    ),
    'is-right': (
        z-index: 4,
        transform: translateX(30%) scale(1.1)
    ),
    'is-last-two:nth-child(2)': (
        margin-right: $offset-last-desktop
    ),
    'is-last': (
        margin-right: $offset-mid-desktop
    )
);
