.fan-engagement {
    position: relative;

    &__container {
        padding-top: var(--g-layout-widget-spacing);
        padding-bottom: var(--g-layout-widget-spacing);

        // remove gutter padding when container is full width
        &--full-width {
            padding-left: 0;
            padding-right: 0;
        }

        // remove all padding if container is empty
        &:empty {
            padding: 0;
        }

        iframe {
            max-width: 100% !important; // stylelint-disable-line declaration-no-important
        }
    }
}
