// Styles for: Main video
// Notes: Main video style for all devices

// CONFIG

:root {
    --videoPlayer-root-color: var(--g-color-system-dark);
}

// LARGE VIDEO POD

.o-large-video-pod {
    padding: $spacing-xl 4.8rem;
    @media (max-width: $break-tablet) {
        padding: 1.6rem;
    }

    @each $size,
        $values
            in (
                small: (
                    1.6rem 2.4rem,
                    0.8rem
                ),
                medium: (
                    3.2rem 4.8rem,
                    1.6rem
                ),
                large: (
                    4rem 7.2rem,
                    2.4rem
                )
            )
    {
        body[data-theme-widget-card-inner-spacing='#{$size}'] & {
            padding: nth($values, 1);

            @media (max-width: $break-tablet) {
                padding: nth($values, 2);
            }
        }
    }

    &__title {
        @include heading-L;
        color: var(--videoPlayer-root-color);
    }

    &__description {
        @include body-medium;
        margin-top: 1.6rem;
        color: var(--videoPlayer-root-color);

        &:empty {
            display: none;
        }
    }

    &__meta {
        display: flex;
        justify-content: space-between;
        color: var(--videoPlayer-root-color);
    }

    // OVERRIDES

    .o-content-meta {
        margin-bottom: 2.6rem;
    }

    .w-share {
        margin-bottom: 2.6rem;

        &__icon {
            fill: var(--videoPlayer-root-color);
        }
    }

    .o-video-duration {
        @include meta-2;
        opacity: 0.5;
        margin-bottom: 0.6rem;
        line-height: 2rem;
        white-space: nowrap;
        background-color: transparent;
    }

    .full-width-video {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: $break-tablet) {
        padding: 3.4rem 1.6rem;

        .o-content-meta {
            margin-bottom: 1.6rem;
        }

        .w-share {
            margin: 2.4rem 0 0.8rem;
            justify-content: flex-start;
        }
    }

    @media (max-width: $break-phablet) {
        padding: 1.6rem 1.6rem 2.4rem;

        &__description {
            margin-top: 0.8rem;
            @include body-small;
        }
    }
}
