// **** This is a sub widget, colours inherit from _header.scss ***** //

.header-quick-links {
    $this: &;
    background: var(--header-container-backgroundColor);

    &__list {
        display: flex;
    }

    &__list-item {
        flex: 1;
    }

    &__link {
        align-items: center;
        color: var(--header-navigationMobileMenuLink-color);
        display: flex;
        font-size: var(--c-title-xsmall-mobile);
        font-weight: $bold;
        justify-content: center;
        min-height: 4rem;
        padding: 1.2rem;
        position: relative;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
    }

    &__link--active {
        #{$this}__icon {
            transform: rotate(180deg);
        }
    }

    &__icon {
        display: inline-block;
        fill: var(--header-navigationMobileMenuLink-color);
        height: 0.5rem;
        margin-inline-start: 0.6rem;
        transition: 0.15s ease-in-out transform;
        width: 0.8rem;
    }

    &__sub-nav {
        background: var(--header-container-backgroundColor);
        box-shadow: -16px 11px 20px 0 rgba($black, 0.2);
        display: none;
        max-height: 32rem;
        overflow: auto;
        padding: 0.8rem;
        position: absolute;
        top: 100%;
        width: 100%;
    }

    &__sub-nav-link {
        align-items: center;
        color: var(--header-navigationMobileMenuLink-color);
        display: flex;
        font-size: var(--c-title-xsmall-mobile);
        font-weight: $bold;
        justify-content: center;
        min-height: 4.8rem;
        padding: 1.2rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
    }
}
