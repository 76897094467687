// Styles for: Sidebar
// Notes: Sidebar widget style for all devices

// CONFIG

:root {
    --video-player-brand-bar-colour: var(--g-color-brand-primary);
}

.w-video-sidebar {
    position: relative;
    flex: 4;
    max-width: 49rem;

    &__up-next {
        @include heading-S;
        color: var(--video-player-text-color);

        &-item {
            margin-bottom: 2.4rem;
            @each $size,
                $values
                    in (
                        extra-small: 0.2rem,
                        small: 0.8rem,
                        medium: 1.6rem,
                        large: 2.4rem
                    )
            {
                body[data-theme-widget-card-outer-spacing='#{$size}'] & {
                    margin-bottom: nth($values, 1);
                }
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.4rem;
        @each $size,
            $values
                in (
                    extra-small: 0.2rem,
                    small: 0.8rem,
                    medium: 1.6rem,
                    large: 2.4rem
                )
        {
            body[data-theme-widget-card-outer-spacing='#{$size}'] & {
                margin-bottom: nth($values, 1);
            }
        }
    }

    &__content {
        height: 100%;
        padding: 2.4rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        overflow-y: auto;
        z-index: $level-one;

        &::-webkit-scrollbar {
            width: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
            color: var(--g-color-brand-primary);
            background: var(--g-color-brand-primary);
        }
    }

    &__overlay {
        background-color: var(--video-player-text-color);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.1;
    }

    &__list-item {
        margin-bottom: 1.2rem;
        @each $size,
            $values
                in (
                    extra-small: 0.1rem,
                    small: 0.4rem,
                    medium: 0.8rem,
                    large: 1.2rem
                )
        {
            body[data-theme-widget-card-outer-spacing='#{$size}'] & {
                margin-bottom: nth($values, 1);
            }
        }
    }

    &__list-item--active {
        .list-item__now-playing {
            display: block;
        }
    }

    &__autoplay-label {
        color: var(--video-player-text-color);
        @include heading-XS;
    }

    &__load-more-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.6rem;
    }

    &__loader {
        animation: spin 1s linear infinite;
        display: block;
        height: 6rem;
        width: 6rem;
        opacity: 0.2;
        margin: 0 auto;
    }

    .o-cta {
        justify-content: center;
        width: 100%;
    }

    .o-placeholder__svg {
        max-width: 9rem;
    }

    @media (max-width: $break-tablet) {
        max-width: none;

        &__content {
            padding: 1.6rem;
            position: relative; // override position: absolute
        }
    }
}
