// Styles for: Embeddable quote widget
// Notes: Embeddable quote widget style for all devices
// stylelint-disable selector-max-type

// EMBEDDABLE QUOTE

.w-embeddable-quote {
    --embeddableQuote-root-backgroundColor: var(--g-embed-background-color);
    --embeddableQuote-icon-fill: var(--g-embed-text-color);
    --embeddableQuote-text-color: var(--g-embed-text-color);
    --embeddableQuote-author-color: var(--g-embed-text-color);
    --embeddableQuote-root-border: var(--c-border-none);

    $quote-large-width: 80rem;
    $quote-in-line-width: 64rem;
    position: relative;
    margin: 0 auto;
    padding: calc(var(--g-style-widget-spacing) / 2) 0;
    background-color: var(--embeddableQuote-root-backgroundColor);
    border: var(--embeddableQuote-root-border);

    &--full-width {
        max-width: 100%;
    }

    &--large {
        max-width: $quote-large-width;
    }

    &--in-line {
        max-width: $quote-in-line-width;
    }

    &__icon {
        position: absolute;
        width: 7rem;
        height: 7rem;
        fill: var(--embeddableQuote-icon-fill);

        &--left {
            top: 0;
            left: 1.6rem;
            transform: rotate(180deg);
        }

        &--right {
            right: 1.6rem;
            bottom: 0;
        }
        @media (max-width: $break-phablet) {
            width: 3rem;
            height: 3rem;
        }
    }

    blockquote {
        margin: 0;
        padding: 0 8.6rem;
        border-inline-start: none;
        color: var(--embeddableQuote-text-color);
        text-align: center;
        @media (max-width: $break-phablet) {
            padding: 0 4.6rem;
        }

        p {
            @include heading-M;
            color: var(--embeddableQuote-text-color);
        }
    }

    &__wrapper {
        position: relative;
        max-width: 76rem;
    }

    &__author {
        @include meta-1;
        margin-top: 2.4rem;
        font-style: normal;
        text-transform: uppercase;
        color: var(--embeddableQuote-author-color);
    }
}
