:root {
    --form-section-background-color: var(--g-widget-background);
    --form-background-color: var(--g-widget-background);
    --form-text-color: var(--g-color-system-dark);
}

.form {
    $this: &;
    @include text-copy;
    background: var(--form-section-background-color);
    color: var(--form-text-color);
    padding: 5.6rem;

    &--center {
        text-align: center;
    }

    &__wrapper {
        background: var(--form-background-color);
        border-radius: var(--g-layout-border-radius);
        margin: 0 auto;
        max-width: 80rem;
        padding: 5.6rem 8rem;
    }

    &__title {
        @include heading-XL;
    }

    &__description {
        display: block;
        @include text-misc;
        font-size: 1.6rem;
        margin-top: 1.6rem;
    }

    &__container {
        margin-top: 4.8rem;
    }

    &__label {
        color: var(--form-text-color);
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin: 0;
        @include text-misc;
    }

    &__label-madatory {
        color: $color-accent;
    }

    &__field-description {
        @include text-misc;
        font-size: 1.2rem;
        line-height: 2rem;
        padding-left: 0.5rem;
        opacity: 0.5;
    }

    &__field-container {
        position: relative;

        &:last-of-type {
            #{$this}__input-container {
                margin-bottom: 0;
            }
        }
    }

    &__field-info {
        align-items: center;
        display: flex;
        margin-bottom: 0.8rem;
    }

    &__field-info-text {
        flex: 1;
        display: flex;
    }

    &__submit {
        background-color: var(--g-button-primary-background);
        border: var(--g-button-primary-border-color);
        box-shadow: 2px 4px 10px rgba($black, 0.2);
        color: var(--g-button-primary-color);
        font-family: var(--g-typography-application-button);
        font-size: 1.4rem;
        font-weight: $bold;
        height: 4.4rem;
        margin-top: 4.8rem;
        padding: 0 9.5rem;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            background-color: var(--g-button-primary-hover-overlay);
            transition: opacity 0.25s;
        }

        @include hover {
            &::after {
                opacity: 1;
            }
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    &__loader {
        font-size: 0;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__submit-icon {
        animation: rotate 1s linear infinite forwards;
        fill: $white;
        height: 1.8rem;
        width: 1.8rem;
    }

    &__input-container {
        margin-bottom: 2.4rem;
    }

    &__input::placeholder {
        opacity: 0.5;
        color: $grey-500;
    }

    &__input {
        display: block;
    }

    &__input--select,
    &__input--time {
        color: $grey-500;
    }

    &__error {
        align-items: center;
        background: rgba($error, 0.7);
        color: $white;
        display: none;
        @include text-misc;
        font-weight: $bold;
        margin-top: 1.2rem;
        padding: 0.6rem;
    }

    &__error-icon {
        fill: $white;
        height: 1.4rem;
        margin-inline-end: 0.6rem;
        width: 1.4rem;
    }

    &__field-info-icon {
        display: none;
        fill: rgba($error, 0.7);
        height: 1.2rem;
        width: 1.2rem;
    }

    &__field-info-icon--valid {
        fill: var(--form-text-color);
    }

    &__field-container--invalid {
        #{$this}__field-info-icon--invalid {
            display: block;
        }

        #{$this}__field-info-icon--valid {
            display: none;
        }

        #{$this}__error {
            display: flex;
        }

        #{$this}__input:invalid {
            border: 1px solid $error;
        }
    }

    &__field-container--valid {
        #{$this}__field-info-icon--invalid {
            display: none;
        }

        #{$this}__field-info-icon--valid {
            display: block;
        }

        #{$this}__input--select,
        #{$this}__input--time {
            color: $black;
        }
    }

    &__checkbox,
    &__radio-label {
        &:hover {
            text-decoration: underline;
        }
    }

    &__checkbox,
    &__radio-group {
        color: var(--form-text-color);
        display: flex;
        margin-top: 1.2rem;
    }

    &__checkbox-label,
    &__radio-label {
        @include text-misc;
        display: flex;
        font-size: 1.6rem;
        font-weight: $regular;
    }

    &__success {
        align-items: center;
        color: var(--form-message-color);
        display: flex;
        @include heading-S;
        margin: 5rem 0;
    }

    &__success-icon {
        flex-shrink: 0;
        fill: var(--form-message-color);
        height: 3.2rem;
        margin-inline-end: 1.8rem;
        width: 3.2rem;
    }

    @media (max-width: $break-phablet) {
        padding: 1.6rem 0.8rem;

        &__container {
            margin-top: 3.2rem;
        }

        &__wrapper {
            padding: 1.6rem 0.8rem;
        }

        &__description {
            font-size: 1.4rem;
            line-height: 2rem;
            margin-top: 0.8rem;
        }

        &__submit {
            margin-top: 3.2rem;
            width: 100%;
        }

        &__success {
            font-size: 1.6rem;
            line-height: 2rem;
            margin: 3.2rem 0 0;
        }

        &__success-icon {
            height: 3.3rem;
            width: 3.3rem;
        }
    }
}
