// stylelint-disable selector-no-qualifying-type, selector-max-type

html[dir='rtl'] {
    select {
        background-position: left 2rem center;
    }

    .w-header {
        &__search {
            right: auto;
            left: 6.8rem;
        }

        .menu {
            right: auto;
            left: 0;
        }
    }

    .cta-direction {
        transform: scaleX(-1);
    }

    .page-nav-more {
        &__list-container {
            left: 0;
            right: auto;

            &--floating::after {
                left: 3.3rem;
                right: auto;
            }
        }
    }

    .accordion__icon {
        transform: rotate(-90deg);
    }

    .accordion--open .accordion__icon {
        transform: rotate(-180deg);
    }

    .content-slider__nav-button-container {
        left: 0;
        right: auto;
    }

    .photo-modal__control-button {
        &--prev {
            right: 0;
            left: auto;
            transform-origin: bottom left;
        }

        &--next {
            left: 0;
            right: auto;
        }
    }

    .form__input--time {
        display: flex;
        flex-direction: row-reverse;
        text-align: end;
    }
}
