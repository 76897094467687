// Styles for: List item
// Notes: List item style for all devices

// CONFIG

:root {
    --listItem-root-backgroundColor: var(--g-component-background);
    --listItem-root-borderRadius: var(--g-layout-border-radius);
    --listItem-root-border: var(--c-border-none);
    --listItem-root-boxShadow: var(--g-layout-box-shadow);
    --listItem-title-color: var(--g-color-system-dark);
    --listItem-title-fontSize-desktop: var(--c-title-large-desktop);
    --listItem-title-fontSize-desktop-large: var(--c-title-xlarge-desktop);
    --listItem-title-fontSize-mobile: var(--c-title-xlarge-mobile);
    --listItem-summary-fontSize-desktop: var(--c-text-medium-desktop);
    --listItem-summary-fontSize-mobile: var(--c-text-medium-mobile);
    --listItem-summary-color: var(--g-color-system-dark);
    --listItem-ctaText-color-default: var(--g-color-system-light);
    --listItem-ctaText-color-hover: var(--g-color-system-light);
    --listItem-cta-backgroundColor-default: var(--g-color-brand-primary);
    --listItem-cta-backgroundColor-hover: var(--g-color-brand-primary);
    --listItem-cta-boxShadow-default: var(--g-layout-box-shadow);
    --listItem-cta-boxShadow-hover: var(--g-layout-box-shadow);
}

// LIST ITEM

.list-item {
    $this: &;
    width: 100%;
    border: var(--listItem-root-border);
    border-radius: var(--listItem-root-borderRadius);
    overflow: hidden;
    background-color: var(--video-player-color);
    box-shadow: var(--listItem-root-boxShadow);
    transition: transform 0.15s ease-in-out;
    height: 100%;

    &__link {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-decoration: none;
    }

    &__media {
        position: relative;
        width: 42%;
    }

    &__image-container {
        &::after {
            display: block;
            padding-top: calc((10 / 16) * 100%);
            content: '';
        }
    }

    &__heading {
        @include heading-S;
        color: var(--video-player-text-color);
    }

    &__summary {
        @include body-small;
        color: var(--video-player-text-color);
    }

    &__date {
        color: var(--video-player-text-color);
    }

    &__inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        &.is-reversed {
            flex-direction: row-reverse;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        flex-grow: 1;
        gap: 0.8rem;
        padding: $spacing-s;

        @each $size, $value in (small: 0.8rem, medium: 1.2rem, large: 1.6rem) {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: $value;
            }
        }
    }

    &__video-duration {
        z-index: $level-one;
        position: absolute;
        bottom: 0.8rem;
        left: 0.8rem;
    }

    &__now-playing {
        bottom: 0.4rem;
        display: none;
        left: 0.4rem;
    }

    &__video-play {
        z-index: $level-one;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4rem;
        height: 4rem;
        opacity: 0.95;
        border-radius: 50%;
        background: rgba($black, 0.5);
        transition: all 0.25s ease-in-out;
        transform: translate(-50%, -50%);
    }

    &__video-play-icn {
        fill: $white;
        position: absolute;
        width: 100%;
        height: 80%;
        margin: auto;
        inset: 0;
    }

    &__playing {
        @include meta-1;
        margin-right: 1.2rem;
        margin-bottom: 0.6rem;
        padding: 0.4rem 0.6rem;
        border-radius: var(--g-layout-border-radius);
        color: var(--contentMeta-tag-color);
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        background-color: var(--contentMeta-tag-backgroundColor);
        position: absolute;
        top: 0;
        left: 0;
        display: none;
    }

    &.is-playing {
        #{$this}__playing {
            display: block;
        }
    }

    &__arrow-link-container {
        margin-top: auto;

        .o-arrow-link {
            position: relative;
            margin-top: 1.6rem;
        }
    }

    @include hover {
        @include arrow-link-parent-hover;
        transform: scale(1.03);

        #{$this}__heading {
            text-decoration: underline;
        }

        #{$this}__video-play {
            width: 5rem;
            height: 5rem;
            background: var(--video-play-btn-hover-color);
            box-shadow: var(--g-layout-box-shadow);
        }
    }
}
