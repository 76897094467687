/* MOBILE-FIRST */

.countdown {
    $this: &;

    --countdown-background-color: var(--g-widget-background);
    --countdown-widgetBackground-color: var(--g-color-system-light);
    --countdown-text-color: var(--g-typography-color-widget-component);
    --countdown-digit-background-color: var(--g-typography-color-heading);
    --countdown-digit-text-color: var(--g-typography-color-heading);
    background-color: var(--countdown-background-color);

    .o-widget-card {
        &__container {
            background: var(--countdown-widgetBackground-color);
            position: relative; // allow absolute-positioned background image to relatively position against this
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.6rem;
        position: relative;
        z-index: $level-one;
        min-height: 11.6rem;
    }

    &__content-tile {
        display: flex;
        flex: 1;

        &--end {
            justify-content: flex-end;
        }
    }

    &__content-center {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 1.6rem;
    }

    &__bgimg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.3;
        pointer-events: none;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 25%;
            height: 100%;
            top: 0;
            z-index: $level-one; // allow gradient overlays to cover edges of background image
        }

        &::before {
            left: 0;
            background: linear-gradient(
                270deg,
                rgb(0 0 0 / 0%) 0%,
                var(--countdown-widgetBackground-color) 70%
            );
        }

        &::after {
            right: 0;
            background: linear-gradient(
                90deg,
                rgb(0 0 0 / 0%) 0%,
                var(--countdown-widgetBackground-color) 70%
            );
        }
    }

    &__text-info {
        color: var(--countdown-text-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 0.4rem;
    }

    .o-arrow-link {
        margin-top: 0.4rem;
    }

    &__title {
        @include heading-M;
    }

    &__desc {
        @include text-copy;
        font-size: 1.2rem;
        line-height: 2rem;
    }

    &__timer {
        display: flex;
        justify-content: center;
        gap: 1.5rem;
    }

    &__digit {
        color: var(--countdown-digit-text-color);
        font-weight: $extrabold;
        padding: 0.8rem 1.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        min-width: 7.3rem;
        border-radius: var(--g-layout-border-radius);
        overflow: hidden;

        &:first-of-type::after {
            content: ':';
            position: absolute;
            font-size: var(--c-title-medium-mobile);
            right: -0.75rem;
            font-family: serif;
            top: 50%;
            line-height: 1;
            transform: translateX(50%) translateY(-50%);
        }

        &-number {
            @include heading-L;
        }

        &-label {
            @include text-misc;
            font-size: 1rem;
            line-height: 1.6;
        }

        &-bg {
            background-color: var(--countdown-digit-background-color);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.1;
        }
    }

    &__sponsor {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        padding-top: 1.6rem;

        &-text {
            color: var(--countdown-text-color);
            @include text-misc;
            font-size: 1rem;
            line-height: 1.4rem;
        }

        &-img {
            height: 3.2rem;
            max-width: 13.6rem;
        }
    }

    &__end {
        flex: 1;
        display: grid;
        gap: 0.8rem;

        &-text {
            @include heading-XS;
            font-weight: $bold;
            color: var(--countdown-text-color);
            display: block;
            text-align: center;
        }

        .o-cta {
            justify-content: center;
        }
    }

    @media (min-width: $break-phablet) {
        &__content {
            flex-direction: row;
            justify-content: space-between;
            padding-left: 3.2rem;
            padding-right: 3.2rem;
        }

        &__content-center {
            width: auto;
            padding: 0 1.6rem;
        }

        &__text-info {
            align-items: flex-start;
            text-align: start;

            .o-arrow-link {
                justify-content: flex-start; // override
            }
        }

        &__desc {
            font-size: 1.4rem;
        }

        &__timer {
            gap: 2.6rem;
        }

        &__digit {
            min-width: 8rem;

            &:first-of-type::after {
                right: -1.3rem;
                font-size: var(--c-title-medium-desktop);
            }

            &-label {
                font-size: 1.2rem;
            }
        }

        &__end {
            flex: none;
        }

        &__sponsor {
            flex-direction: column;
            align-items: end;
            padding-top: 0;

            &-text {
                font-size: 1.2rem;
                line-height: 1.6rem;
                max-width: 20rem;
            }

            &-img {
                height: 4rem;
                max-width: 14.4rem;
            }
        }
    }
}
