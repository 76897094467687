:root {
    --searchbar-backgroundColor: var(--g-color-system-light);
    --searchbar-borderColor: var(--g-color-system-dark);
    --searchbar-input-color: var(--g-color-system-dark);
    --searchbar-icon-color: var(--g-color-brand-primary);
    --searchbar-overlay-fade-color: var(--g-color-system-light);
}

.search-bar {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    height: 9.6rem;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(-100%);
    background: var(--searchbar-backgroundColor);
    box-shadow: 0.2rem -0.4rem 1rem 0 hsl(0deg 0% 9% / 10%),
        0 0.7rem 1rem 0 hsl(0deg 0% 9% / 30%);
    transition: opacity 0.25s, transform 0.3s;

    &--inline {
        position: relative;
        left: auto;
        right: auto;
        transform: none;
        opacity: 1;
        box-shadow: -0.4rem 0.2rem 2rem rgb(22 22 22 / 20%);
    }

    &--open {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.4s ease-in, transform 0.3s;
        z-index: $level-one;
    }

    &__cta {
        padding: 1.6rem;
        margin-inline-start: -5.6rem;
        transition: opacity 0.2s ease;

        &:disabled {
            opacity: 0.4;
        }
    }

    &__cta-icon {
        width: 2.4rem;
        height: 2.4rem;
        fill: var(--searchbar-icon-color);
    }

    // being more specific here to override global input[type=text] styling

    .search-bar__input {
        max-width: 80rem;
        margin: 0;
        padding: 1.8rem 6.4rem 1.8rem 1.7rem;
        border: none;
        background: none;
        color: var(--searchbar-input-color);
        font-size: 2rem;
        font-weight: $regular;
        width: 100%;
        outline: none;

        &::placeholder {
            opacity: 0.5;
            @include text-copy;
            color: var(--searchbar-input-color);
        }

        &:focus {
            border: none;
        }
    }
}

@media (max-width: $break-desktop) {
    .search-bar {
        padding: 0 0.8rem;
        height: 7.2rem;
        box-shadow: inset var(--g-layout-box-shadow);
        transition: opacity 0.4s, transform 0.8s;

        &--open {
            transition: opacity 0.4s ease-in, transform 0.3s;
        }

        .search-bar__input {
            font-size: 1.8rem;
        }

        &__cta-icon {
            margin-inline-start: -0.8rem;
        }
    }
}
