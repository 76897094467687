// Styles for: Sponsors widget
// Notes: Sponsors widget style for all devices
// stylelint-disable selector-max-specificity

// CONFIG

:root {
    --sponsors-root-backgroundColor: var(--g-widget-background);
    --sponsors-text-color: var(--g-typography-color-widget-component);
}

$max-items: 8;

// MIXIN

@mixin promos-width($itemsPerRow, $item) {
    width: calc(var(--sponsors-item-width) * $itemsPerRow);

    #{$item} {
        min-width: calc(100% / $itemsPerRow);
        max-width: calc(100% / $itemsPerRow);
    }
}

// SPONSORS

.w-sponsors {
    $this: &;
    position: relative;
    background-color: var(--sponsors-root-backgroundColor);

    // REMOVE SPACING WHEN TWO
    // SPONSORS ON TOP OF EACH OTHER
    // TO ALLOW MULTIPLE LISTS

    + .w-sponsors {
        padding-top: 0;
        margin-top: calc(var(--g-layout-widget-spacing) * -1);
    }

    &--is-large {
        #{$this}__sponsor {
            width: 20rem;
            height: 12rem;
        }
    }

    &__wrapper {
        padding-top: 1.6rem;

        &--border {
            border-top: 0.1rem solid var(--g-color-system-mid);
        }
    }

    &__title {
        @include heading-S;
        margin-bottom: 1.6rem;
        color: var(--sponsors-text-color);
        text-align: center;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: center;
        margin: 0 auto;
    }

    &__item {
        margin-bottom: 2.4rem;
        padding: 0 2.4rem;
    }

    &__item-heading {
        @include text-misc;
        font-size: 1rem;
        text-align: center;
        font-weight: $regular;
    }

    &__item-card {
        color: var(--sponsors-text-color);

        &--link {
            text-decoration: none;

            @include hover {
                text-decoration: underline;
            }
        }
    }

    &__sponsor {
        display: flex;
        z-index: $level-one;
        position: relative;
        flex-shrink: 0;
        align-items: center;
        width: 14rem;
        height: 9rem;
        margin: 0 auto;
        padding: 0 $spacing-m;

        &::after {
            content: '';
            display: block;
            width: 100%;
            padding-top: calc(10 / 16) * 100%;
        }
    }

    &__sponsor-image img,
    &__sponsor-svg {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        max-height: 70%;
        margin: auto;
    }

    @media (min-width: $break-desktop) {
        &__items {
            --sponsors-item-width: 18.8rem;
            padding: 0 2.4rem;

            @for $i from 1 through $max-items {
                &--#{$i} {
                    @include promos-width($i, #{$this}__item);
                }
            }
        }

        &__item {
            flex: 1;
            padding: 0 1.2rem;
        }

        &__sponsor {
            width: auto;
            height: auto;
            padding: 0;
            min-width: auto;
            min-height: 10.5rem;
        }

        &--is-large {
            --sponsors-item-width: 24.8rem;

            #{$this} {
                &__item img {
                    width: 14rem;
                }

                &__sponsor {
                    width: auto;
                    height: auto;
                    min-height: 12rem;
                }
            }
        }
    }

    @media (max-width: $break-tablet) {
        + .w-sponsors {
            margin-top: calc(var(--g-layout-widget-spacing) * -1);
        }
    }

    @media (max-width: $break-phablet) {
        + .w-sponsors {
            margin-top: calc(var(--g-layout-widget-spacing) * -1);
        }

        &--is-large {
            #{$this}__sponsor {
                width: 12rem;
                height: 8rem;
            }
        }

        &__sponsor {
            width: 10rem;
            height: 6rem;
        }

        &__item-heading {
            font-size: 0.8rem;
        }

        &__item {
            margin-bottom: 0.8rem;
            padding: 0;
        }
    }
}
