// Styles for: Language Switcher widget
// Notes: Language Switcher widget style for all devices
// Mobile first

// LANGUAGE SWITCHER

.language-switcher {
    $this: &;
    position: relative;
    transition: all 0.25s;

    &.active {
        #{$this}__menu-list-container {
            opacity: 1;
            flex-direction: column;
            transform: translateY(0.8rem);
            visibility: visible;
        }

        #{$this}__button-icon--caret {
            transform: rotate(180deg);
        }
    }

    &.is-disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &__container {
        position: relative;
        max-width: 20rem;
    }

    &__menu-list {
        z-index: $level-one;
        display: flex;
        flex-direction: column;
        border-radius: var(--g-layout-border-radius);
        box-shadow: var(--g-layout-box-shadow);
        overflow: hidden;

        &-container {
            visibility: hidden;
            opacity: 0;
            display: flex;
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 100%;
            transform: translateY(1.6rem);
            transition: all 0.25s cubic-bezier(0, 0.5, 0.6, 1);
            box-shadow: var(--c-box-shadow-default);

            #{$this}__link-text {
                padding: 1rem 1.6rem;
            }
        }

        &-root {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        &-wrapper {
            width: 100%;
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__link {
        text-decoration: none;
        background-color: var(--c-color-white);
        color: var(--g-color-system-dark);
        white-space: nowrap;
        width: 100%;
        padding: 0.4rem;
        text-align: center;

        @include hover {
            #{$this}__link-text {
                background: var(--g-color-system-mid);
            }
        }
    }

    &__link-text {
        font-weight: $extrabold;
        display: block;
        font-size: 1.4rem;

        &--long {
            display: none;
            text-transform: capitalize;
        }

        &--short {
            text-transform: uppercase;
        }
    }

    &__button {
        color: var(--g-color-system-dark);
        display: flex;
        align-items: center;
        padding: 1rem;
        border-radius: 4rem;
        width: 100%;
        height: 4rem;
        gap: 0.8rem;

        &:focus {
            outline: none;
        }

        &-icon {
            width: 1.2rem;
            height: 1.2rem;
            transition: transform 0.2s ease-out;
        }

        &--close {
            color: $white;
            width: 100%;
            align-content: center;
            justify-content: flex-end;
            padding: 1.7rem 0.8rem;
            border: none;

            .chevron {
                transform: rotate(-90deg);
            }
        }
    }

    &--mobile {
        #{$this} {
            &__container {
                max-width: none;
                overflow: hidden;
            }

            &__button {
                height: 4.8rem;
                padding-left: 2.4rem;
                padding-right: 2.4rem;

                &-icon--caret {
                    margin-left: auto; // right-align caret at mobile
                }

                &-icon--globe {
                    width: 1.6rem;
                    height: 1.6rem;
                }

                #{$this}__link-text {
                    padding: 0;
                }
            }

            &__menu-list-container {
                transform: none !important; /* stylelint-disable-line declaration-no-important */
                box-shadow: none;
                position: relative;
                max-height: 0;
                transition: none;
            }

            &__menu-list {
                box-shadow: none;
                position: relative;

                &::-webkit-scrollbar {
                    width: 0.4rem;
                }

                &::-webkit-scrollbar-thumb {
                    color: var(--g-color-system-mid);
                    background: var(--g-color-system-mid);
                }
            }

            &__link {
                text-align: left;
                opacity: 0.7;
                padding: 0;
            }

            &__link-text {
                padding-left: 2.4rem;
                padding-right: 2.4rem;

                &--short {
                    display: none;
                }

                &--long {
                    display: block;
                }
            }
        }

        &.active {
            #{$this} {
                &__menu-list-container {
                    max-height: 100vh;
                }
            }
        }
    }
}
