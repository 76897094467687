:root {
    --photoGrid-modal-backgroundColor: var(--g-color-system-dark);
    --photoGrid-modal-controlsColor: var(--g-color-brand-accent);
    --photoGrid-modal-textColor: var(--c-color-white);
    --photoGrid-brandBar-background: var(--g-color-gradient-1);
}

.photo-modal {
    background: var(--photoGrid-modal-backgroundColor);
    bottom: 0;
    height: auto;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transition: 0.3s ease-in-out opacity;
    width: auto;
    visibility: hidden;
    z-index: $level-six;

    &--active {
        opacity: 1;
        visibility: visible;
    }

    &__placeholder {
        background: $black;
        width: 100%;
    }

    &__wrapper {
        height: 100%;
        margin: 0 auto;
        max-width: 116rem;
        width: 100%;
    }

    &__image-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        position: relative;
    }

    &__image img {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }

    &__list {
        align-items: center;
        display: flex;
        height: 100%;
    }

    &__list--animate {
        transition: 0.3s ease-in-out transform;
    }

    &__list-item {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        max-height: 100%;
        padding: 0 2rem;
        width: 100%;
    }

    &__control-button {
        background: var(--g-button-primary-background);
        fill: var(--g-button-primary-color);
        height: 6rem;
        outline: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transform-origin: bottom right;
        transition: 0.1s ease-in-out transform;
        width: 6rem;

        &:hover {
            @media (min-width: $break-phablet) {
                transform: translateY(-50%) scale(1.2);
            }
        }
    }

    &__control-button.faded {
        opacity: 0.4;
        pointer-events: none;
    }

    &__control-button--prev {
        left: 0;
        transform-origin: bottom left;
    }

    &__control-button--next {
        right: 0;
    }

    &__arrow {
        height: 3rem;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 2.2rem;
    }

    &__meta-container {
        padding: 2.5rem 0;
    }

    &__image-title {
        color: var(--photoGrid-modal-textColor);
        display: block;
        @include heading-S;
    }

    &__image-description {
        color: var(--photoGrid-modal-textColor);
        display: block;
        @include text-misc;
        font-size: 1.4rem;
        padding: 0.4rem 0;
    }

    &__lazy-load {
        margin: auto 0;
    }

    &__close {
        background: var(--g-button-primary-background);
        height: 5.6rem;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-indent: -999px;
        top: 0;
        width: 5.6rem;
    }

    &__close-icon {
        fill: var(--g-button-primary-color);
        left: 50%;
        height: 1.8rem;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.8rem;
    }

    &__brand-bar {
        background: var(--photoGrid-brandBar-background);
        bottom: 0;
        position: absolute;
        width: 100%;
    }

    @media (max-width: $break-phablet) {
        &__control-button {
            height: 4rem;
            width: 4rem;
        }
    }
}
