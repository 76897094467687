// Styles for: Article widget
// Notes: Article widget style for all devices

// CONFIG

:root {
    --staticContent-root-backgroundColor: var(--g-website-background);
    --staticContent-caption-color: var(--g-color-system-dark);
    --staticContent-text-color: var(--g-color-system-dark);
}

$static-content-constrained-width: 64rem;

// ARTICLE

.w-static-content {
    position: relative;
    background-color: var(--staticContent-root-backgroundColor);

    .w-embeddable-photo__caption,
    .w-embeddable-video__caption,
    .w-embeddable-table__caption,
    .cms-content caption {
        color: var(--staticContent-caption-color);
    }

    .articleWidget:first-of-type {
        margin-top: 0;
    }

    // CONTENT

    &__wrapper {
        max-width: $article-width;
    }

    &__title {
        @include heading-XXL;
        max-width: 80rem;
        margin-right: auto;
        margin-bottom: 5.6rem;
        padding-bottom: 5.6rem;
        margin-left: auto;
        color: var(--staticContent-text-color);
        text-align: center;
        border-bottom: 0.1rem solid var(--g-color-system-mid);
    }

    &__content {
        position: relative;
        margin: 0 auto;

        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6,
        > ul,
        > ol,
        > .table,
        > p,
        > strong,
        > blockquote,
        > pre,
        > table {
            max-width: $static-content-constrained-width;
            margin-right: auto;
            margin-left: auto;
        }

        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6,
        > ul,
        > ol,
        > p,
        > strong,
        > blockquote {
            color: var(--staticContent-text-color);
        }
    }
}
