// Styles for: trumps
// Notes: trumps style for all devices

/* stylelint-disable declaration-no-important  */

.u-hide {
    display: none !important;
}

.u-show {
    display: block !important;
}

.u-visually-hidden {
    visibility: hidden;
}

.u-observed {
    min-width: 0.1rem;
    min-height: 0.1rem;
}

.u-screen-reader {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0.1rem;
    margin: -0.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0.1rem;
}

.u-overflow-hidden {
    overflow: hidden !important;
    height: 100%;
}

.u-margin-left {
    margin-inline-start: 1rem !important;
}

.u-margin-right {
    margin-inline-end: 1rem !important;
}

.u-no-margin {
    margin: 0 !important;
}

.u-no-margin-bottom {
    margin-bottom: 0 !important;
}

.u-no-padding {
    padding: 0 !important;
}

.u-full-height {
    height: 100% !important;
}

.u-full-width {
    width: 100% !important;
}

.u-link-reset {
    color: inherit;
    text-decoration: none;
}

.u-block {
    display: block !important;
}

.u-no-border-radius {
    border-radius: 0 !important;
}

.u-no-shadow {
    box-shadow: none !important;
}

@media (max-width: $break-wide) {
    .u-hide-wide {
        display: none !important;
    }
}

@media (min-width: $break-wide) {
    .u-no-margin-bottom-wide {
        margin-bottom: 0 !important;
    }
}

.u-no-font {
    font-size: 0;
}

.u-text-upper {
    text-transform: uppercase !important;
}

.u-flex-tablet {
    @media (max-width: $break-tablet) {
        display: flex;
        flex-direction: column;
    }
}

.u-no-shrink {
    flex-shrink: 0 !important;
}

@media (max-width: $break-desktop) {
    .u-hide-desktop {
        display: none;
    }
}

@media (min-width: $break-desktop) {
    .u-show-desktop {
        display: none;
    }
}

@media (max-width: $break-tablet) {
    .u-body-no-scroll {
        position: fixed;
    }

    .u-hide-tablet {
        display: none;
    }

    .u-display-tablet {
        display: inline-block !important;
    }
}

@media (min-width: $break-tablet) {
    .u-show-tablet {
        display: none !important;
    }

    .u-no-padding-tablet {
        padding: 0 !important;
    }
}

@media (max-width: $break-phablet) {
    .u-hide-phablet {
        display: none !important;
    }

    .u-no-padding-phablet {
        padding: 0 !important;
    }

    .u-full-width-phablet {
        width: 100% !important;
    }
}

@media (min-width: $break-phablet) {
    .u-show-phablet {
        display: none !important;
    }
}

@media (max-width: $break-desktop) {
    .u-hide-desktop {
        display: none !important;
    }
}

@media (min-width: $break-mobile) {
    .u-show-mobile {
        display: none;
    }
}

@media (max-width: $break-mobile) {
    .u-hide-mobile {
        display: none;
    }
}
/* stylelint-enable */
