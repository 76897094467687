// Styles for: Notice
// Notes: Notice style for all devices

.notice {
    text-align: center;
    width: 100%;
    margin-top: $spacing-xl;

    &__title {
        @include heading-L;
    }

    &__summary {
        @include body-medium;
    }
}
