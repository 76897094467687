// stylelint-disable at-rule-empty-line-before

$use-px: false;

/* SETTINGS
========================================================================== */

@import 'includes/settings/normalize';

@import 'includes/settings/variables';

@import 'includes/global/fonts';

@import 'includes/global/mixins';

/* VENDORS
========================================================================== */

/* LAYOUT
========================================================================== */

@import 'includes/layout/main';

@import 'includes/layout/block-list';

@import 'includes/layout/grid-list';

/* GLOBAL
========================================================================== */

@import 'includes/global/global';

@import 'includes/global/rtl';

@import 'includes/global/typography';

@import 'includes/global/loader';

@import 'includes/global/notice';

@import 'includes/global/animations';

@import 'includes/global/transitions';

@import 'includes/global/cms-content';

@import 'includes/global/table';

@import 'includes/global/blockquote';

/* THEMES
========================================================================== */

@import "includes/themes/_example-theme.scss";
@import "includes/themes/_theme-function.scss";

/* SPRITES
========================================================================== */

// @import 'includes/sprites/sprite-includes';

/* COMPONENTS
========================================================================== */

@import "includes/components/_content-slider.scss";
@import "includes/components/_embed-widgets.scss";
@import "includes/components/_filter-group.scss";
@import "includes/components/_filter.scss";
@import "includes/components/_forms.scss";
@import "includes/components/_o-arrow-link.scss";
@import "includes/components/_o-brand-bar.scss";
@import "includes/components/_o-content-meta.scss";
@import "includes/components/_o-cta.scss";
@import "includes/components/_o-custom-checkbox.scss";
@import "includes/components/_o-gameweek.scss";
@import "includes/components/_o-large-media-pod.scss";
@import "includes/components/_o-match-card.scss";
@import "includes/components/_o-media-pod.scss";
@import "includes/components/_o-overlay-media-pod.scss";
@import "includes/components/_o-placeholder.scss";
@import "includes/components/_o-promo-pod.scss";
@import "includes/components/_o-video-duration.scss";
@import "includes/components/_o-wide-media-pod.scss";
@import "includes/components/_o-wide-small-media-pod.scss";
@import "includes/components/_o-widget-card.scss";
@import "includes/components/_o-widget-header.scss";
@import "includes/components/_object-fit-cover-picture.scss";
@import "includes/components/_object-fit-cover-video.scss";
@import "includes/components/_page-nav.scss";
@import "includes/components/_pager.scss";
@import "includes/components/_picture.scss";
@import "includes/components/_progress-bar.scss";
@import "includes/components/_related-content-thumbnail.scss";
@import "includes/components/_youtube-video.scss";

/* WIDGETS
========================================================================== */

/** If widget folder structure is changed this needs updating **/

@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/accordion/styles/_accordion.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/article-page/styles/_article-page.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/clickable-image/styles/clickable-image-block.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/content-featured-with-overlay/styles/_content-featured-with-overlay.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/content-featured-with-overlay/styles/_fvp-controls.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/content-featured-with-overlay/styles/_fvp-slider.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/content-featured/styles/_content-featured.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/content-listing/styles/_content-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/countdown/styles/_slim.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/document/styles/document-download.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/embeddable-photo/styles/_embeddable-photo.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/embeddable-quote/styles/_embeddable-quote.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/embeddable-table/styles/_embeddable-table.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/embeddable-video/styles/_embeddable-video.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/embeddable/styles/_embed-script.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/event-calendar/styles/calendar.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/event-details-page/styles/event-details-page.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/fan-engagement/styles/_fan-engagement.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/footer/styles/_additional-links.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/footer/styles/_footer.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/form/styles/form.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/gallery-page/styles/_masonry-grid.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/gallery-page/styles/_photo-modal.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/header/styles/_header-quick-links.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/header/styles/_header.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/header/styles/_language-switcher.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/header/styles/_top-bar.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/multi-item-hero/styles/_content-hero.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/page-title/styles/_page-title.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/player/styles/player-card.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/player/styles/player-list.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/player/styles/player-profile.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/portrait-player/styles/_portrait-player-card.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/portrait-player/styles/_portrait-player-list.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/promo-featured/styles/_promo-featured.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/promo-listing/styles/_promo-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/promo-listing/styles/_simple-promo-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/promo/styles/_slim.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/search/styles/_search-bar.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/search/styles/_search.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/share/styles/_share.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/single-item-hero/styles/_featured.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/sponsors/styles/_sponsors.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/stacked-content-carousel/styles/_stacked-content-carousel.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/stacked-content-carousel/styles/classes-mixin.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/static-content/styles/_static-content.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/video/styles/list-item.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/video/styles/main-video.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/video/styles/sidebar.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/video/styles/up-next-item.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/wiad/video/styles/video-player.scss";

/* WRAPS
========================================================================== */



/* UTILITIES
========================================================================== */

@import "includes/utilities/_trumps.scss";

// stylelint-enable
