/* stylelint-disable declaration-no-important */
.document-download {
    $this: &;

    --download-document-text-color: var(--g-embed-text-color);
    --download-document-backgroundColor: var(--g-embed-background-color);
    --download-border-radius: var(--g-layout-border-radius);
    padding: calc(var(--g-style-widget-spacing) / 2) 2.4rem;

    &__element {
        background: var(--download-document-backgroundColor);
        box-shadow: 2px 4px 10px rgba($black, 0.1);
        display: block;
        margin: 0 auto !important;
        padding: 1.6rem 2.4rem;
        text-decoration: none !important;
        overflow: hidden;
        border-radius: var(--download-border-radius);
        transition: 0.15s ease-in-out box-shadow;

        &:hover {
            box-shadow: 2px 4px 20px 5px rgba($black, 0.05);

            #{$this}__title {
                text-decoration: underline;
            }

            #{$this}__icon {
                transform: scale(1.25);
            }
        }
    }

    &__section {
        align-items: center;
        display: flex;
    }

    &__title {
        // important so when widget is embedded in article the sizing isn't overriden
        color: var(--download-document-text-color) !important;
        @include heading-XS;
        margin: 0 !important;

        @media (max-width: $break-phablet) {
            font-size: var(--c-title-small-mobile);
        }
    }

    &__description {
        color: var(--download-document-text-color) !important;
        @include text-copy;
        font-size: 1.4rem !important;
        line-height: 2rem !important;
        margin: 0.8rem 0 0 !important;
    }

    &__icon {
        fill: var(--download-document-text-color);
        height: 2rem;
        width: 2rem;
        transition: 0.15s ease-in-out transform;
    }

    &__text {
        flex-grow: 1;
    }

    &--wide {
        max-width: 80rem;
    }

    &--default {
        max-width: 64rem;
    }

    &--full {
        width: 100%;
    }
}
/* stylelint-enable declaration-no-important */
