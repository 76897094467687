// Styles for: Video player
// Notes: Video player widget style for all devices

// CONFIG

:root {
    --video-player-color: var(--g-color-system-dark);
    --video-play-btn-hover-color: var(--g-color-gradient-1);
    --video-player-text-color: var(--g-color-system-light);
    --videoPlayer-sidebar-backgroundColor: var(--g-color-system-light);
    --videoPlayer-sidebarItem-backgroundColor: var(--g-color-system-light);
    --videoPlayer-sidebarItem-color: var(--g-color-system-dark);
    --videoPlayer-sidebarItem-boxShadow: var(--g-layout-box-shadow);
    --videoPlayer-sidebarItem-border: var(--c-border-none);
    --videoPlayer-sidebarItemBrandBar-background: var(--g-color-brand-primary);
}

// VIDEO PLAYER

.w-video-player {
    background: var(--video-player-color);

    &__container {
        @include wrapper;
        display: flex;
        overflow: hidden;
        min-height: 86rem;
    }

    .full-width-video {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 6;
    }

    &__title,
    &__description {
        color: var(--video-player-text-color);
    }

    // CONTENT META

    .o-content-meta {
        &__item {
            &::before {
                background: var(--video-player-text-color);
            }

            &--text {
                color: var(--video-player-text-color);
            }
        }
    }

    .w-share {
        &__icon {
            fill: var(--video-player-text-color);
        }
    }

    &__details {
        flex: 1;
        padding: 3.2rem 4.8rem;
    }

    &__brand-bar {
        width: 100%;
        height: 0.8rem;
        background: var(--video-player-brand-bar-colour);

        &--mobile {
            display: none;
        }
    }

    @media (max-width: $break-desktop) {
        &__container {
            min-height: 78rem;
        }
    }

    @media (max-width: $break-tablet) {
        &__container {
            flex-direction: column;
            padding: 0;
        }

        &__details {
            padding: 1.6rem;
        }

        &__brand-bar {
            &--desktop {
                display: none;
            }

            &--mobile {
                display: block;
            }
        }
    }
}

// FULL VIDEO

.full-width-video {
    video::-webkit-media-controls-start-playback-button {
        display: none;
    }

    .inline-player {
        aspect-ratio: 16/9;
    }

    .inline-player__content {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        @supports (aspect-ratio: 16 / 9) {
            height: 100%;
            padding-top: 0;
        }
        cursor: pointer;

        .vjs-big-play-button::before {
            display: none;
        }

        .vjs-big-play-button::after {
            background: url($img-dir + '/elements/play.svg') no-repeat 100%;
            content: '';
            display: block;
            height: 100%;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 74%;
        }

        .vjs-menu {
            display: none !important; // stylelint-disable-line declaration-no-important
        }

        .vjs-big-play-button,
        &.vjs-mouse .vjs-big-play-button {
            display: block;
            width: 12rem;
            height: 12rem;
            margin: 0;
            border-radius: 100%;
            opacity: 0.95;
            background: var(--g-color-system-dark);
            background-repeat: no-repeat;
            background-position: center center;
            transition: all 0.25s ease-in-out;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include hover {
                background: var(--video-play-btn-hover-color);
                width: 13rem;
                height: 13rem;
                box-shadow: var(--wideSmallMediaPod-root-boxShadow);
            }

            .vjs-icon-placeholder {
                display: none;
            }
        }

        .vjs-control-bar {
            border: none;
            background-color: transparent;
            background-image: linear-gradient(
                to bottom,
                rgba($black, 0) 0%,
                rgba($black, 0.75) 100%
            );
        }

        &.vjs-playing .vjs-big-play-button {
            display: none;
        }

        .vjs-big-play-button:hover,
        .vjs-big-play-button:focus {
            box-shadow: none;
        }

        &::-webkit-full-screen {
            width: 100% !important; // stylelint-disable-line declaration-no-important
        }

        .vjs-load-progress {
            background-color: rgba($black, 0.8);
        }

        .vjs-play-progress {
            position: relative;
            background-color: var(--g-color-brand-primary);
            background-image: none;
            box-shadow: 0 0 0.4rem 0.2rem rgb(var(--g-color-brand-primary) 0.4);

            &::before {
                content: none;
            }

            &::after {
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                width: 1rem;
                height: 1rem;
                border-radius: 100%;
                content: '';
                background-color: var(--g-color-brand-primary);
                box-shadow: 0 0 0.4rem 0.1rem rgba($black, 0.3);
                transform: translateY(-50%);
            }
        }

        .vjs-volume-level {
            background-color: var(--g-color-brand-primary);

            &::before {
                color: var(--g-color-brand-primary);
            }
        }

        &.vjs-paused {
            .vjs-control-bar {
                transform: translateY(3em);
            }

            &.vjs-fullscreen {
                /* stylelint-disable selector-max-specificity */

                &.vjs-control-bar {
                    transform: translateY(0);
                }

                /* stylelint-enable selector-max-specificity */
            }
        }
    }

    .vjs-play-progress,
    .vjs-volume-level {
        background-color: var(--g-color-brand-primary);
    }

    .vjs-dock-text {
        display: none;
    }

    @media (max-width: $break-phablet) {
        .inline-player__content {
            .vjs-big-play-button,
            &.vjs-mouse .vjs-big-play-button {
                height: 9rem;
                width: 9rem;
            }
        }
    }
}
