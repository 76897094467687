// Styles for: Embeddable photo widget
// Notes: Embeddable photo widget style for all devices

// EMBEDDABLE PHOTO

.w-embeddable-photo {
    $this: &;

    --embeddablePhoto-caption-color: var(--g-embed-caption-color);

    $photo-large-width: 80rem;
    $photo-in-line-width: 64rem;
    padding: calc(var(--g-style-widget-spacing) / 2) 0;

    &__inner {
        margin: 0 auto;
    }

    &--large {
        #{$this}__inner {
            max-width: $photo-large-width;
        }
    }

    &--in-line {
        #{$this}__inner {
            max-width: $photo-in-line-width;
        }
    }

    &__media {
        position: relative;

        .lazy-image-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__image-container {
        position: relative;

        &::before {
            content: '';
            display: block;
            padding-bottom: calc((10 / 16) * 100%);
        }
    }

    &__caption {
        @include meta-2;
        opacity: 0.5;
        max-width: $photo-in-line-width;
        margin: 0 auto;
        padding: 1.6rem 1.2rem;
        color: var(--embeddablePhoto-caption-color);
        text-align: center;
    }
}
