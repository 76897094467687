.player-list {
    --player-list-background-colour: var(--g-widget-background);
    --player-list-title-color: var(--g-typography-color-widget-heading);
    --player-list-brandBar-background: var(--g-color-gradient-1);
    background: var(--player-list-background-colour);

    // widget header override
    .o-widget-header {
        &__title {
            color: var(--player-list-title-color);
        }

        &__brand-bar {
            background: var(--player-list-brandBar-background);
        }
    }
}
