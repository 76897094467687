.fvp-slider {
    $this: &;
    $cycle-duration: 5s;
    $image-border: 0.3rem;
    $widget-gutter: 4.4rem;
    overflow: scroll;
    padding: 1rem 0;
    transition: all 0.5s ease-in-out;
    scrollbar-width: none; /* Firefox */
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    &.is-paused {
        #{$this}__bar-loader {
            animation-play-state: paused;
        }
    }

    &.is-stopped {
        #{$this}__bar-container {
            display: none;
        }
    }

    &__slider {
        height: 100%;
        width: 100%;
        display: flex;
        gap: 1.2rem;
        @each $size,
            $values
                in (
                    extra-small: 0.1rem,
                    small: 0.4rem,
                    medium: 0.8rem,
                    large: 1.2rem
                )
        {
            body[data-theme-widget-card-outer-spacing='#{$size}'] & {
                gap: $values;
            }
        }
    }

    &__bar {
        display: none;
        background-color: rgb(255 255 255 / 50%);

        &-container {
            position: absolute;
            bottom: 0.4rem;
            left: 0;
            width: 100%;
            height: 0.5rem;
            padding: 0 0.4rem;
        }

        &-loader {
            height: 0.5rem;
            animation-name: expand-width;
            animation-duration: $cycle-duration;
            animation-timing-function: linear;
            animation-play-state: running;
        }
    }

    &__item-wrapper {
        display: flex;
        scroll-snap-align: start;
        pointer-events: all;

        // Buffer pseudo element to ensure slider ends in line with page content
        &:first-of-type::before,
        &:last-of-type::after {
            content: '';
            height: 1rem;
            flex-shrink: 0;
        }

        &.is-active {
            #{$this}__bar {
                display: block;
            }
        }

        &:hover {
            cursor: pointer;

            #{$this}__title {
                text-decoration: underline;
            }
        }
    }

    &__item {
        width: calc(16rem + (#{$image-border} * 2));
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        pointer-events: none;
    }

    &__image {
        position: relative;
        width: 100%;
        height: calc(10rem + (#{$image-border} * 2));
        pointer-events: none;
        border-radius: var(--g-style-rounded-corners);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__placeholder {
        width: 16rem;
        height: 10rem;
        border-radius: var(--g-style-rounded-corners);
        overflow: hidden;
    }

    &__title {
        text-overflow: ellipsis;
        font-size: 1.2rem;
        font-weight: $extrabold;
        line-height: 1.25;
        pointer-events: none;
        @include truncate-multiline(2, 1.25);
    }

    &--desktop {
        margin-right: -$widget-gutter;

        #{$this}__item-wrapper:last-of-type:after {
            width: $widget-gutter;
            min-width: $widget-gutter;
        }
    }

    @media (max-width: $break-tablet) {
        padding: 1.6rem 0;

        #{$this}__item-wrapper:first-of-type:before,
        #{$this}__item-wrapper:last-of-type:after {
            width: $widget-gutter;
            min-width: $widget-gutter;
        }
    }

    @media (max-width: $break-phablet) {
        #{$this}__item-wrapper:first-of-type:before,
        #{$this}__item-wrapper:last-of-type:after {
            width: 1.6rem;
            min-width: 1.6rem;
        }
    }
}
