:root {
    --portrait-player-list-background-colour: var(--g-widget-background);
    --portrait-player-list-title-color: var(
        --g-typography-color-widget-heading
    );
    --portrait-player-list-brandBar-background: var(--g-color-gradient-1);
}

.portrait-player-list {
    $this: &;
    background: var(--portrait-player-list-background-colour);

    &__title {
        color: var(--portrait-player-list-title-color);
    }

    &__brand-bar {
        background: var(--portrait-player-list-brandBar-background);
    }

    &__link {
        border-radius: var(--g-layout-border-radius);
        box-shadow: var(--g-layout-box-shadow);
        overflow: hidden;
    }

    @media (max-width: $break-phablet) {
        // At mobile, force first card to stretch full width (if hero class applied)
        &__item {
            &--hero {
                grid-area: 1 / 1 / 1 / 3;
            }
        }
    }
}
