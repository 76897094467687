:root {
    --portrait-player-card-background-colour: var(--g-color-system-dark);
    --portrait-player-card-text-colour: var(--c-color-white);
    --portrait-player-card-overlay-colour: var(--c-color-black);
}

.portrait-player-card {
    $this: &;
    color: var(--portrait-player-card-text-colour);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 40rem;
    overflow: hidden;
    text-align: center;
    background: var(--portrait-player-card-background-colour);
    position: relative;
    transition: 0.15s ease-in-out transform;

    // Override for the text colour of metadata elements
    .o-content-meta {
        justify-content: center;

        &__item {
            font-weight: $bold;

            &::before {
                background: var(--portrait-player-card-text-colour);
            }

            &--text {
                color: var(--portrait-player-card-text-colour);
            }
        }
    }

    &--left {
        text-align: start;

        #{$this}__meta {
            padding: 2.4rem;
        }

        #{$this}__surname-background {
            width: 100%;
            margin-left: -1.2rem;
        }

        #{$this}__tags-container,
        #{$this}__tags-inner-container {
            justify-content: flex-start;
        }

        .o-content-meta {
            justify-content: flex-start;
        }
    }

    &--rounded-profile {
        min-height: 32rem;
        padding-top: 4rem;

        #{$this}__meta {
            margin-top: 0;
        }

        #{$this}__image-placeholder {
            scale: 1;
            opacity: 0.8;
            margin-top: 0.95rem;
            mix-blend-mode: normal;
        }

        #{$this}__image-rounded-container {
            aspect-ratio: 1/1;
            width: 16rem;
            height: 16rem;
            background: $white;
            border: 0.4rem solid $white;
            border-radius: 100%;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
        }
    }

    &__image-container {
        display: flex;
        position: absolute;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
        max-height: calc(100% - 4rem);
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &__image-container--standard {
        height: 100%;
        max-height: 100%;

        #{$this}__picture img {
            max-width: none;
            object-position: 50% 20%;
        }
    }

    &__image-rounded-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image-placeholder {
        position: relative;
        height: 100%;
        fill: $grey-400;
        opacity: 0.2;
        mix-blend-mode: difference;
        scale: 1.6;
        max-height: 22rem;
    }

    &__picture img {
        object-position: top;
        max-width: 36rem;
        left: 50%;
        transform: translateX(-50%);
    }

    &__lazy-load {
        margin: 0 auto;
    }

    &__background-image-overlay,
    &__background-image-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $level-negative;
        height: 100%;
        width: 100%;
    }

    &__background-image-overlay {
        pointer-events: none;

        &::before,
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        &::before {
            background: linear-gradient(
                90deg,
                var(--portrait-player-card-background-colour) 0%,
                transparent 100%
            );
        }

        &::after {
            background: linear-gradient(
                90deg,
                transparent 0%,
                var(--portrait-player-card-background-colour) 100%
            );
        }
    }

    &__background-image {
        opacity: 0.7;
        height: 100%;
        width: 100%;
    }

    &__meta {
        margin-top: auto;
        position: relative;
        padding: $spacing-l;
        @media (max-width: $break-tablet) {
            padding: 1.6rem;
        }

        @each $size,
            $values
                in (
                    small: (
                        1.6rem,
                        0.8rem
                    ),
                    medium: (
                        2.4rem,
                        1.6rem
                    ),
                    large: (
                        3.2rem,
                        2.4rem
                    )
                )
        {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: nth($values, 1);

                @media (max-width: $break-tablet) {
                    padding: nth($values, 2);
                }
            }
        }
    }

    &__meta-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        position: relative;
        z-index: $level-two;
    }

    &__name-container {
        position: relative;
        min-height: 6.4rem;
    }

    &__forename {
        @include text-copy;
        font-size: var(--c-text-large-desktop);
        font-weight: $regular;
        line-height: 1.4;
        min-height: 1.6rem;
        @include truncate-multiline(1, 1.4);
    }

    &__surname {
        font-size: var(--c-title-medium-desktop);
        font-weight: $bold;
        margin-top: -0.4rem;
        line-height: 1.25;
        display: block;
        @include truncate();
    }

    &__surname-background {
        position: absolute;
        font-size: var(--c-title-xxlarge-desktop);
        font-weight: $bold;
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: -0.08rem;
        white-space: nowrap;
        opacity: 0.1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__tags-container {
        justify-content: center;
        max-height: 4rem;
        overflow: hidden;
        gap: 0 0.8rem;

        &.has-hidden-items {
            #{$this}__tags-inner-container {
                max-width: calc(100% - 2rem);
            }
        }
    }

    &__tags-inner-container {
        justify-content: center;
        flex-wrap: wrap;
        max-width: 100%;
        gap: 0.8rem;
    }

    &__tag {
        font-size: 1.2rem;
        line-height: 2rem;
        margin-bottom: 1.2rem;

        &:first-child {
            @include truncate-multiline(1, 2);
        }
    }

    &__tag-more {
        padding-left: 0;
        padding-right: 0;
        background: none;
        margin-bottom: 1.2rem;
    }

    &:hover {
        @include arrow-link-parent-hover;
        transform: scale(1.03);

        #{$this}__forename,
        #{$this}__surname {
            text-decoration: underline;
        }
    }

    &__background {
        position: absolute;
        width: 100%;
        pointer-events: none;
        height: 28rem;
        bottom: 0;
        z-index: $level-one;

        &::before,
        &::after,
        span::before,
        span::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 19.5rem;
            background: linear-gradient(
                to top,
                var(--portrait-player-card-overlay-colour) 0%,
                transparent 100%
            );
            mix-blend-mode: overlay;
        }

        &::after {
            height: 17rem;
            mix-blend-mode: overlay;
            opacity: 0.6;
        }

        span {
            position: absolute;
            display: block;
            width: 100%;
            bottom: 0;

            &::before {
                height: 12rem;
                mix-blend-mode: multiply;
            }

            &::after {
                height: 8rem;
                mix-blend-mode: multiply;
            }
        }
    }

    @media (max-width: $break-phablet) {
        min-height: 28rem;

        &--left {
            #{$this}__meta {
                padding: 1.6rem;
            }

            #{$this}__surname-background {
                margin-left: -0.8rem;
            }
        }

        &--rounded-profile {
            min-height: 23.6rem;
            padding-top: 2rem;

            #{$this}__meta {
                padding: 1.6rem 1.2rem;
            }

            #{$this}__image-rounded-container {
                width: 13.4rem;
                height: 13.4rem;
                border: 0.375rem solid $white;
            }
        }

        &__image-container {
            max-height: calc(100% - 1.6rem);

            &--standard {
                max-height: 100%;

                #{$this}__image-placeholder {
                    margin-top: 6rem;
                }

                #{$this}__picture img {
                    max-width: none;
                }
            }
        }

        &__image-placeholder {
            scale: 1.3;
            top: 0.5rem;
        }

        &__picture img {
            max-width: 26.4rem;
        }

        &__name-container {
            min-height: 4.5rem;
        }

        &__forename {
            font-size: var(--c-text-medium-mobile);
            line-height: 1.43;
            min-height: 1.2rem;
        }

        &__surname {
            font-size: var(--c-title-medium-mobile);
            margin-top: -0.2rem;
            line-height: 1.2;
        }

        &__surname-background {
            font-size: var(--c-title-xxlarge-mobile);
            margin-left: 0.6rem;
        }

        &__meta {
            font-size: 1rem;
            padding: 1.6rem 1.2rem;
        }

        &__tag {
            font-size: 1rem;
            line-height: 1.4;
            margin-bottom: 0.8rem;
        }

        &__tags-container {
            max-height: 3rem;
        }

        &__tag-more {
            margin-bottom: 0.8rem;
        }
    }
}