// Styles for: Content featured
// Notes: Content featured widget style for all devices

// CONFIG

:root {
    --contentFeatured-root-backgroundColor: var(--g-widget-background);
    --contentFeatured-mediaPodCta-boxShadow-default: var(
        --wideMediaPod-cta-boxShadow-default
    );
    --contentFeatured-mediaPodCta-boxShadow-hover: var(
        --wideMediaPod-cta-boxShadow-hover
    );
    --contentFeatured-mediaPodImageContainer-backgroundColor: var(
        --wideMediaPod-imageContainer-backgroundColor
    );
    --contentFeatured-mediaPod-boxShadow: var(--wideMediaPod-root-boxShadow);
    --contentFeatured-mediaPod-border: var(--wideMediaPod-root-border);
    --contentFeatured-mediaPod-backgroundColor: var(--g-component-background);
    --contentFeatured-text-color: var(--g-typography-color-widget-component);
    --contentFeatured-overlay-text-color: var(--c-color-white);
    --contentFeatured-button-color: var(--c-color-white);
    --contentFeatured-mediaPodBrandBar-background: var(
        --wideMediaPod-brandBar-background
    );
    --contentFeatured-contentMetaTag-backgroundColor: var(
        --contentMeta-tag-backgroundColor
    );
    --contentFeatured-mediaPod-overlayColor: var(--c-color-black);
    --contentFeatured-slides-backgroundColor: var(--c-color-black);
    --contentFeatured-slides-activeColor: var(--g-color-brand-primary);
    --contentFeatured-slides-textColor: var(--c-color-white);
    --contentFeatured-carousel-loader: var(--c-color-white);
}

.w-content-featured-with-overlay {
    $this: &;
    background-color: var(--contentFeatured-root-backgroundColor);
    position: relative;

    &--full-width {
        #{$this}__wrapper {
            max-width: none;
            padding: 0;
        }

        .o-overlay-media-pod {
            border-radius: 0;
        }

        // Handle desktop FVP slider overflow for full-width layout
        .fvp-slider--desktop {
            width: calc(
                50vw - (1.6rem / 2)
            ); // 1.6rem is the gutter between the two content cards

            .fvp-slider__item-wrapper {
                &:last-of-type::after {
                    width: calc(
                        ((100vw - #{$wrapper-width}) / 2) + 4.4rem
                    ); // 4.4rem is the widget gutter
                }
            }
        }
    }

    .o-overlay-media-pod {
        background-color: var(--contentFeatured-mediaPod-backgroundColor);
        box-shadow: var(--contentFeatured-mediaPod-boxShadow);
        border: var(--contentFeatured-mediaPod-border);

        &__heading,
        &__summary {
            color: var(--contentFeatured-overlay-text-color);
        }

        &__icon {
            fill: var(--contentFeatured-overlay-text-color);
        }

        .o-arrow-link {
            color: var(--contentFeatured-button-color);

            svg {
                fill: var(--contentFeatured-button-color);
            }
        }

        &__background {
            &::before {
                background: linear-gradient(
                    3deg,
                    var(--contentFeatured-mediaPod-overlayColor) 0%,
                    transparent 50%
                );
            }

            &::after {
                background: linear-gradient(
                    8deg,
                    var(--contentFeatured-mediaPod-overlayColor) 0%,
                    transparent 50%
                );
            }
        }
    }

    .o-content-meta {
        transform: scale(1);

        &__item {
            &::before {
                background: var(--contentFeatured-overlay-text-color);
            }

            &--text,
            &--tag {
                color: var(--contentFeatured-overlay-text-color);
            }
        }
    }

    // Theming styles for featured slider
    .fvp-slider {
        &__title {
            color: var(--contentFeatured-overlay-text-color);
        }

        &__item-wrapper {
            &:hover {
                .fvp-slider__image {
                    background: var(--contentFeatured-overlay-text-color);
                }
            }

            &.is-active {
                .fvp-slider__image {
                    background: var(--contentFeatured-slides-activeColor);
                }
            }
        }

        &__bar-loader {
            background-color: var(--contentFeatured-carousel-loader);
        }

        &__button {
            &-icon {
                fill: var(--contentFeatured-overlay-text-color);
            }
        }

        &--mobile {
            background-color: var(--contentFeatured-slides-backgroundColor);

            .fvp-slider__title {
                color: var(--contentFeatured-slides-textColor);
            }
        }
    }
}
