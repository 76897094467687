.fvp-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.6rem;

    &__button {
        width: 3.2rem;
        height: 3.2rem;
        padding: 0.4rem;
        opacity: 0.5;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        &.is-disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        &--video {
            width: 5.6rem;
            height: 5.6rem;
            padding: 0.7rem;
            margin-inline-start: 0.8rem;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    @media (max-width: $break-tablet) {
        &__button {
            opacity: 1;

            &--slider {
                display: none;
            }

            &--video {
                position: absolute;
                top: 1.6rem;
                right: 1.6rem;
            }
        }
    }
}
