// Styles for: Header widget
// Notes: Header widget style for all devices
// stylelint-disable selector-max-specificity, declaration-no-important, no-duplicate-selectors, max-empty-lines, declaration-block-no-redundant-longhand-properties

// CONFIG

:root {
    --header-container-backgroundColor: var(--g-color-system-light);
    --header-brandBar-background: var(--g-color-gradient-1);
    --header-dividingLine-backgroundColor: var(--g-color-system-dark);
    --header-navigationLink-color: var(--g-color-system-dark);
    --header-navigationLink-borderBottom-color-hover: var(
        --g-color-brand-primary
    );
    --header-navigationLink-borderBottom-color-active: var(
        --g-color-brand-primary
    );
    --header-navigationSublink-backgroundColor: var(--g-color-system-light);
    --header-navigationSublink-color-default: var(--g-color-system-dark);
    --header-navigationSublink-borderBottom-color-hover: var(
        --g-color-system-mid
    );
    --header-navigationSublink-borderBottom-color-active: var(
        --g-color-brand-primary
    );
    --header-navigationSublink-boxShadow: var(--g-layout-box-shadow);
    --header-navigationMobileMenu-backgroundColor: var(--g-color-system-light);
    --header-navigationMobileMenuLink-borderBottom-color-active: var(
        --g-color-brand-accent
    );
    --header-navigationMobileMenuLink-color: var(--g-color-system-dark);
    --header-navigationMobileMenuOpenAccordion-backgroundColor: var(
        --g-color-system-mid
    );
    --header-navigationMobileMenuOpenAccordion-color: var(
        --g-typography-color-widget-component
    );
}

// HEADER PARENT

.w-header-parent {
    // z-index and important needed for when element is rendered within visual editor
    z-index: $level-six;
    position: sticky !important;
    top: 0;
}

// HEADER

.w-header {
    $this: &;
    $mobile-nav-gutter: 2.4rem;
    $mobile-nav-height: 5.2rem;
    $mobile-button-height: 4.4rem;
    $mobile-button-padding: 0.8rem;
    box-shadow: var(--header-boxShadow);

    &__main {
        z-index: $level-two;
        position: relative;
        width: 100%;
        margin: auto;
        padding: 0 $spacing-xs;
        background: var(--header-container-backgroundColor);
    }

    &__navigation-parent {
        flex-grow: 1;
    }

    // SOCIAL

    &__social {
        display: none;
        justify-content: center;
        margin: 2.6rem 0;

        &-item {
            margin: 0 1.5rem;
        }

        &-icon {
            opacity: 0.5;
            width: 2rem;
            height: 2rem;
            fill: var(--header-navigationMobileMenuLink-color);

            @include hover {
                transition: transform 0.2s;
                transform: scale(1.1);
            }
        }
    }

    // BRAND BAR

    &__brand-bar {
        position: relative;
        height: 0.8rem;
        background: var(--header-brandBar-background);
    }

    // CONTENT

    &__content {
        @include wrapper(false);
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;

        // NAVIGATON ALIGNMENT
        &--centre {
            .linked-list {
                justify-content: center;
            }
        }

        &--right {
            .linked-list {
                justify-content: flex-end;
            }
        }

        &--left {
            .linked-list {
                justify-content: flex-start;
            }
        }
    }

    // BRAND

    &__brand {
        display: flex;
        position: relative;
        flex-shrink: 0;
        align-items: center;
        width: 14rem;
        height: 8rem;
        margin: 0 $spacing-m;

        &-image {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: auto;
            max-height: 75%;
            margin: auto;
        }

        &::after {
            content: '';
            display: block;
            width: 100%;
            padding-top: calc(9 / 16) * 100%;
        }
    }

    // MENU - DESKTOP

    &__menu {
        flex-grow: 1;
        height: 100%;

        .linked-list {
            display: flex;
            height: 100%;
            padding: 0.6rem 0;
            text-align: center;

            &__link-text {
                @include text-misc;
                pointer-events: none;
                display: flex;
                align-items: center;
                gap: 0.8rem;
            }

            &__item {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
            }

            // MENU - DESKTOP: LEVEL 1

            &__dropdown-label,
            &__link {
                width: 100%;
                margin: 0;
                padding: 2.4rem $spacing-m 2.3rem;
                border: none;
                color: var(--header-navigationLink-color);
                @include heading-XS;
                text-decoration: none;
                appearance: none;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 0.4rem;
                    background: var(
                        --header-navigationLink-borderBottom-color-hover
                    );
                    opacity: 0;
                    transition: opacity ease 0.25s;
                }

                &.is-open {
                    .linked-list__button-arrow {
                        transform: rotate(180deg);
                    }
                }

                @include hover {
                    &::after {
                        opacity: 1;
                    }
                }

                &.is-active {
                    &::after {
                        opacity: 1;
                        background: var(
                            --header-navigationLink-borderBottom-color-active
                        );
                    }
                }
            }

            &__dropdown-label {
                display: flex;
                align-items: center;
                gap: 0.8rem;
            }

            // MENU - DESKTOP: ICONS

            &__button-arrow,
            &__external-arrow {
                position: relative;
                pointer-events: none;
                fill: var(--header-navigationLink-color);
            }

            &__button-arrow {
                width: 1.2rem;
                height: 1.2rem;
            }

            &__external-arrow {
                width: 0.8rem;
                height: 0.8rem;
            }

            // MENU - DESKTOP: LEVEL 2

            &__items {
                padding: $spacing-xs;
                background-color: var(
                    --header-navigationSublink-backgroundColor
                );

                .linked-list {
                    // Override (remove) primary menu hover state on dropdown items as they have their own hover state
                    &__dropdown-label,
                    &__link {
                        text-align: initial;

                        &::after {
                            content: none;
                        }
                    }

                    &__external-arrow {
                        fill: var(--header-navigationSublink-color-default);
                    }

                    &__link {
                        margin: 0;
                        padding: 1.7rem $spacing-s 1.8rem;
                        border-bottom: none;
                        color: var(--header-navigationSublink-color-default);
                        line-height: normal;
                        text-transform: none;
                        transition: color ease-in-out 0.25s,
                            border-bottom ease-in-out 0.25s,
                            background-color ease-in-out 0.25s;

                        &-text {
                            position: relative;
                            display: inline-flex;

                            &::after {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                bottom: -0.8rem;
                                left: 0;
                                width: 100%;
                                height: 0.4rem;
                                transition: background-color ease 0.25s;
                            }
                        }

                        @include hover {
                            border-bottom: none;
                            color: var(
                                --header-navigationSublink-color-default
                            );

                            .linked-list__link-text {
                                &::after {
                                    background: var(
                                        --header-navigationSublink-borderBottom-color-hover
                                    );
                                }
                            }
                        }

                        &.is-active {
                            border-bottom: none;

                            .linked-list__link-text {
                                &::after {
                                    background: var(
                                        --header-navigationSublink-borderBottom-color-active
                                    );
                                }
                            }
                        }
                    }
                }
            }

            // MENU - DESKTOP: DROPDOWN

            &__dropdown {
                opacity: 0;
                position: absolute;
                top: 100%;
                left: 50%;
                width: 24rem;
                pointer-events: none;
                box-shadow: var(--header-navigationSublink-boxShadow);
                transition: opacity 0.25s cubic-bezier(0, 0.5, 0.6, 1),
                    transform 0.25s cubic-bezier(0, 0.5, 0.6, 1);
                transform: translateY(1rem) translateX(-50%);

                &.is-open {
                    opacity: 1;
                    pointer-events: auto;
                    transform: translateY(0) translateX(-50%);

                    .linked-list__dropdown-arrow {
                        display: block;
                    }
                }

                .linked-list {
                    &__items {
                        background: var(
                            --header-navigationSublink-backgroundColor
                        );
                        max-height: 48rem;
                        overflow-y: auto;
                    }

                    &__dropdown-arrow {
                        display: none;
                        position: absolute;
                        top: -1.35rem;
                        width: 100%;
                        height: 1.4rem;
                        background: var(
                            --header-navigationSublink-backgroundColor
                        );
                        clip-path: polygon(
                            50% 0%,
                            calc(50% - 1.35rem) 100%,
                            calc(50% + 1.35rem) 100%
                        );
                    }
                }
            }
        }
    }

    // CTA

    &__cta {
        display: flex;
        margin: $spacing-m;
    }

    // SEARCH

    &__search {
        margin-left: 1.2rem;
        padding: 1.2rem;

        &-icon {
            position: relative;
            margin-left: 0;
            width: 2.4rem;
            height: 2.4rem;
            color: var(--header-navigationLink-color);
            direction: ltr; // ensure that `direction: rtl` doesn't break icon alignment
            text-decoration: none;
            transition: background-color 0.2s ease;

            &-upper {
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 100%;
                border: 0.3rem solid var(--header-navigationLink-color);
                transform: translate(0);
                transition: transform 0.1s 0.1s ease;
            }

            &-middle,
            &-lower {
                height: 0.3rem;
                position: absolute;
                background-color: var(--header-navigationLink-color);
            }

            &-middle {
                width: 3.4rem;
                top: 0.3rem;
                right: 0.4rem;
                transform: rotate(-45deg) scale(0);
                transform-origin: top right;
                opacity: 0;
                transition: bottom 0.1s 0s ease, opacity 0.1s 0s ease,
                    transform 0.2s 0s ease;
            }

            &-lower {
                width: 1.2rem;
                bottom: 0;
                right: 0.1rem;
                transform: rotate(45deg);
                transform-origin: bottom right;
                transition: bottom 0.1s 0s ease, right 0.1s 0s ease,
                    width 0.2s 0s ease;
            }
        }

        &-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--searchbar-overlay-fade-color);
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease;
            z-index: 1;

            &.search-overlay--open {
                opacity: 0.5;
                visibility: visible;
            }
        }

        &.search-icon--open {
            #{$this} {
                &__search-icon-upper {
                    transform: translate(0.2rem, 0.2rem) rotate(-45deg)
                        rotateX(90deg);
                    transition-delay: 0.1s;
                }

                &__search-icon-middle {
                    top: 0;
                    right: 0.2rem;
                    width: 2.8rem;
                    opacity: 1;
                    transform: rotate(-45deg) scale(1);
                    transition: bottom 0.1s 0.1s ease, opacity 0.2s 0.1s ease,
                        transform 0.2s 0.1s ease;
                }

                &__search-icon-lower {
                    width: 2.8rem;
                    right: 0.2rem;
                    bottom: 0.2rem;
                    transition-delay: 0.1s;
                }
            }
        }

        &:focus {
            outline: none;
        }
    }

    // Hide secondary nav and burger menu CTA by default at desktop - reveal at mobile view
    &__secondary-navigation,
    &__burger-menu {
        display: none;
    }

    .language-switcher__button {
        color: var(--header-navigationLink-color);

        &-icon {
            fill: var(--header-navigationLink-color);
        }
    }

    // Modifier class programmatically applied when nav is in burger view to ensure transitions only occur in this state
    // Prevents nav transition animations from occurring when switching between mobile and desktop view
    &--transition-ready {
        #{$this} {
            &__navigation-parent {
                transition: opacity ease-in-out 0.25s;
            }

            &__cta {
                transition: transform ease-in-out 0.25s,
                    opacity ease-in-out 0.25s;
            }
        }
    }

    // Modifier class applied when divider line config is set to on
    &--dividing-line {
        &::before {
            content: '';
            opacity: 0.1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0.1rem;
            background-color: var(--header-dividingLine-backgroundColor);
        }
    }

    // MOBILE VIEW
    // ====================================

    @media (max-width: $break-desktop) {
        &__brand-bar {
            height: 0.4rem;
            z-index: $level-two;
        }

        // BRAND LOGO

        &__brand {
            margin: 0 $spacing-xs;
            width: 8.6rem;
            height: 4.8rem;
        }

        &__search {
            position: absolute;
            right: 6.8rem;
        }

        &__burger-menu {
            display: flex;
            z-index: $level-two;
            position: absolute;
            right: 0;
            align-items: center;
            height: 100%;
            padding: 0 1.6rem;
            border: none;
            text-decoration: none;
            background-color: transparent;
            transition: border-bottom ease 0.25s;
            appearance: none;

            &-icon {
                position: relative;
                top: 0;
                width: 2.4rem;
                height: 2.4rem;
                margin: 0;
                fill: var(--header-navigationLink-color);

                &--chevron {
                    width: 2.9rem;
                    height: 2.8rem;
                }

                &--close {
                    display: none;
                }
            }
        }

        // MENU - MOBILE

        &__navigation-parent {
            opacity: 0;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100%);
            margin-top: $mobile-nav-height;
            padding-bottom: calc(
                $mobile-button-height + ($mobile-button-padding * 2) +
                    $mobile-nav-height
            );
            pointer-events: none;
            background: var(--header-navigationMobileMenu-backgroundColor);
        }

        &__navigation-scroll {
            height: 100%;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 0.4rem;
                background: $color-mid;
            }

            &::-webkit-scrollbar-thumb {
                color: var(--g-color-brand-primary);
                background: var(--g-color-brand-primary);
            }
        }

        &__menu {
            height: auto; // prevent mobile menu from spanning 100% height so secondary nav can dock beneath it

            &::after,
            &::before {
                content: '';
                display: block;
                opacity: 0.2;
                width: 100%;
                height: 0.1rem;
                background-color: var(--header-navigationMobileMenuLink-color);
            }

            .linked-list {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0;

                &__button-arrow,
                &__external-arrow {
                    fill: var(--header-navigationMobileMenuLink-color);
                }

                &__item {
                    flex-direction: column;
                    width: 100%;
                }

                &__link-text {
                    &::after {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        bottom: -0.4rem;
                        left: 0;
                        width: 100%;
                        height: 0.2rem;
                        background: transparent;
                        transition: background-color ease 0.25s;
                    }
                }

                // MENU - MOBILE: LEVEL 1

                &__link,
                &__dropdown-label {
                    position: relative;
                    padding: 1.6rem $mobile-nav-gutter;
                    border: none;
                    color: var(--header-navigationMobileMenuLink-color);
                    text-align: left;
                    font-size: var(--c-text-medium-mobile);

                    // Override (remove) primary menu hover state on mobile items
                    &::after {
                        content: none;
                    }

                    &.is-active {
                        .linked-list__link-text::after {
                            background: var(
                                --header-navigationMobileMenuLink-borderBottom-color-active
                            );
                        }
                    }
                }

                &__dropdown-label {
                    &.is-open .linked-list__button-arrow {
                        transform: rotate(180deg);
                    }
                }

                &__button-arrow {
                    position: absolute;
                    right: $spacing-l;
                    transition: ease-in-out transform 0.25s;
                    fill: var(--header-navigationMobileMenuLink-color);
                }

                // MENU - MOBILE: LEVEL 2

                &__items {
                    padding: 0;
                    background-color: var(
                        --header-navigationMobileMenu-backgroundColor
                    );

                    .linked-list {
                        &__button-arrow,
                        &__external-arrow {
                            fill: var(--header-navigationMobileMenuLink-color);
                        }

                        &__item {
                            margin: 0;
                        }

                        &__link-text {
                            &::after {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                bottom: -0.4rem;
                                left: 0;
                                width: 100%;
                                height: 0.2rem;
                                background-color: transparent;
                                transition: background-color ease 0.25s;
                            }
                        }

                        &__link {
                            padding: 1.6rem $mobile-nav-gutter;
                            border: none;
                            color: var(--header-navigationMobileMenuLink-color);
                            font-size: 1.6rem;

                            @include hover {
                                border: none;

                                .linked-list__link-text {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }

                            &.is-active {
                                border: none;

                                .linked-list__link-text {
                                    &::after {
                                        background: var(
                                            --header-navigationMobileMenuLink-borderBottom-color-active
                                        );
                                    }
                                }
                            }
                        }
                    }
                }

                // MENU - MOBILE: DROPDOWN

                &__dropdown {
                    display: none;
                    opacity: 1;
                    position: static;
                    pointer-events: initial;
                    box-shadow: none;
                    transform: none !important;

                    .linked-list {
                        &__items {
                            background: var(
                                --header-navigationMobileMenuOpenAccordion-backgroundColor
                            );
                            color: var(
                                --header-navigationMobileMenuOpenAccordion-color
                            );
                        }

                        &__link {
                            color: var(
                                --header-navigationMobileMenuOpenAccordion-color
                            );
                        }
                    }

                    &.is-open {
                        display: block;
                        left: 0;
                        width: 100%;

                        .linked-list__dropdown-arrow {
                            display: none;
                        }
                    }
                }
            }
        }

        &__secondary-navigation {
            display: block;

            .linked-list {
                display: flex;
                flex-direction: column;

                &__item {
                    display: flex;
                }

                &__items {
                    display: none;
                }

                // SECONDARY NAV - MOBILE: LEVEL 1

                &__dropdown-label,
                &__link {
                    position: relative;
                    width: 100%;
                    margin: 0;
                    padding: 1.2rem $mobile-nav-gutter;
                    border: none;
                    color: var(--header-navigationMobileMenuLink-color);
                    font-size: var(--c-text-small-mobile);
                    font-weight: $bold;
                    text-align: left;
                    text-decoration: none;
                    transition: border-bottom ease 0.25s;
                    appearance: none;
                }

                &__link-text {
                    display: inline-flex;
                    align-items: center;
                    gap: 0.8rem;
                }

                // SECONDARY NAV - MOBILE: ICONS

                &__button-arrow,
                &__external-arrow {
                    position: relative;
                    top: 0;
                    fill: var(--header-navigationMobileMenuLink-color);
                }

                &__external-arrow {
                    width: 0.8rem;
                    height: 0.8rem;
                }

                &__button-arrow {
                    display: none;
                }
            }

            &::after {
                content: '';
                display: block;
                height: 0.1rem;
                width: 100%;
                opacity: 0.2;
                background-color: var(--header-navigationMobileMenuLink-color);
            }
        }

        .language-switcher {
            &__button {
                color: var(--header-navigationMobileMenuLink-color);

                &-icon {
                    fill: var(--header-navigationMobileMenuLink-color);
                }
            }

            &::after {
                content: '';
                display: block;
                height: 0.1rem;
                width: 100%;
                opacity: 0.2;
                background-color: var(--header-navigationMobileMenuLink-color);
            }
        }

        &__social {
            display: flex;
        }

        &__content {
            justify-content: center;
            height: 4.8rem;
        }

        // CTA

        &__cta {
            opacity: 0;
            position: fixed;
            bottom: 0;
            left: 0;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            transform: translateY(100%);
            width: 100%;
        }

        &__button-container {
            padding: 0.8rem;
            background-color: var(
                --header-navigationMobileMenu-backgroundColor
            );
        }

        .o-cta {
            width: 100%;
        }

        &--open-menu {
            #{$this} {
                &__navigation-parent {
                    opacity: 1;
                    pointer-events: all;
                }

                &__cta {
                    opacity: 1;
                    transform: translateY(0);
                }

                &__burger-menu-icon {
                    &--chevron {
                        display: none;
                    }

                    &--close {
                        display: block;
                    }
                }

                &__search {
                    display: none;
                }
            }
        }
    }
}
