// Styles for: Footer widget
// Notes: Footer widget style for all devices

// CONFIG

// CONFIG

:root {
    --footer-root-backgroundColor: var(--g-widget-background);
    --footer-text-color: var(--g-typography-color-widget-component);
    --footer-brandBar-background: var(--g-color-gradient-1);
}

// FOOTER

.w-footer {
    background: var(--footer-root-backgroundColor);
    position: relative;

    // WRAPPER

    &__wrapper {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }

    // BRAND BAR

    &__brand-bar {
        position: relative;
        height: 1.6rem;
        background: var(--footer-brandBar-background);
    }

    // BRAND

    &__brand {
        display: flex;
        position: relative;
        align-items: center;
        width: 14rem;
        height: 8rem;

        &::after {
            content: '';
            display: block;
            width: 100%;
            padding-top: calc(9 / 16) * 100%;
        }

        &-image {
            display: block;
            position: absolute;
            width: auto;
            height: 100%;
        }
    }

    // TOP

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // BOTTOM

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3.6rem;
    }

    &__copyright {
        color: var(--footer-text-color);
        @include text-misc;
        font-size: 1.2rem;
        line-height: 1.5;
    }

    // NAVIGATION

    &__navigation {
        display: flex;
        flex-grow: 1;
        align-items: center;
        margin-right: auto;
    }

    &__links {
        flex-grow: 1;
        color: var(--footer-text-color);
        @include heading-XS;

        .linked-list {
            display: flex;
            flex-wrap: wrap;
            padding: 0;

            &__item {
                &::after {
                    display: inline-block;
                    width: 0.5rem;
                    height: 0.5rem;
                    content: '';
                    vertical-align: middle;
                    border-radius: 50%;
                    background-color: var(--footer-text-color);
                }

                &:first-child {
                    .linked-list__link {
                        margin-left: 0;
                    }
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }

            &__link {
                display: inline-block;
                margin: 0.5rem 0.8rem 0.5rem 1rem;
                text-decoration: none;
                color: var(--footer-text-color);
                font-weight: $bold;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }

    // SOCIAL

    &__social {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        gap: $spacing-m;

        &-icon {
            width: 2.6rem;
            height: 2.6rem;
            fill: var(--footer-text-color);

            @include hover {
                transition: transform 0.2s;
                transform: scale(1.1);
            }
        }
    }

    @media (max-width: $break-phablet) {
        &__top,
        &__bottom {
            flex-direction: column;
            align-items: center;
        }

        &__brand {
            justify-content: center;
            margin-bottom: 2.4rem;
        }

        &__bottom {
            margin-top: 4rem;
        }

        &__copyright {
            font-size: 1rem;
            margin-bottom: 1.6rem;
        }

        &__links .linked-list {
            flex-direction: column;
            align-items: center;

            &__item {
                &::after {
                    display: none;
                }
            }

            &__link {
                margin: 1.2rem 0;
            }
        }

        &__navigation {
            justify-content: center;
            margin: 0 0 4rem;
        }

        &__social {
            justify-content: center;
            width: 100%;
        }
    }
}
