// Styles for: Accordion widget
// Notes: Accordion widget style for all devices
// stylelint-disable selector-max-type

// EMBEDDABLE ACCORDION

.accordion {
    --accordion-title-color: var(--g-typography-color-widget-component);
    --accordion-text-color: var(--g-typography-color-widget-component);
    padding: calc(var(--g-style-widget-spacing) / 2) 0;

    &__container {
        margin: 0 auto;
        max-width: 64rem;
    }

    &__title {
        @include heading-XS;
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        gap: 1.3rem;
        width: 100%;
        padding-top: 0.8rem;
        transition: all ease-in 0.2s;

        p {
            display: table-cell;
            line-height: 1.2;
            @include heading-S;
            color: var(--accordion-title-color);
        }

        @media (max-width: $break-phablet) {
            padding: 0;
        }
    }

    &__icon {
        pointer-events: none;
        transform: rotate(0.25turn) translateX(-0.2rem);
        transition: all ease-in 0.1s;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.4rem;

        svg {
            width: 1.6rem;
            height: 1.6rem;
            flex-shrink: 0;
            fill: var(--accordion-title-color);
        }
        @media (max-width: $break-phablet) {
            margin-top: 0.6rem;

            svg {
                width: 1.4rem;
                height: 1.2rem;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding: 0 2.8rem;
        max-height: 0;
        transition: max-height ease-in 0.2s;
        overflow: hidden;
        @include text-copy;

        p,
        div,
        ul li,
        ol li,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: var(--accordion-text-color);
        }

        p,
        a,
        ul li,
        ol li {
            font-size: 1.4rem;
            line-height: 2rem;
        }

        h6 {
            font-size: 1.6rem;
        }

        @media (min-width: $break-phablet) {
            p,
            a,
            ul li,
            ol li {
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }
    }

    &--open {
        margin-bottom: 0.8rem;

        + .accordion__body {
            margin-bottom: 1.6rem;
        }

        .accordion__icon {
            transform: rotate(180deg);
        }
    }
}
