:root {
    --player-list-photo-background-colour: var(--g-color-brand-secondary);
    --player-list-card-background-colour: var(--g-component-background);
    --player-card-brand-bar-background: var(--g-color-gradient-1);
    --player-list-text-colour: var(--g-typography-color-widget-component);
}

.player-card {
    $this: &;
    border-radius: var(--g-layout-border-radius);
    box-shadow: var(--g-layout-box-shadow);
    color: var(--player-list-text-colour);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    transition: 0.15s ease-in-out transform;
    width: 100%;

    &__image-container {
        background: linear-gradient(
            to top,
            var(--player-list-photo-background-colour) 75%,
            transparent 25%
        );
        display: flex;
        font-size: 0;
        height: 15.7rem;
        position: relative;
        text-align: center;
    }

    &__image-container--standard {
        background: var(--player-list-photo-background-colour);
        padding-top: 1.2rem;
    }

    &__image-placeholder {
        height: 100%;
        margin: 0 auto;
        fill: $color-dark;
    }

    &__lazy-load {
        margin: 0 auto;
    }

    &__image-rounded-container {
        aspect-ratio: 1/1;
        background: $white;
        border: 5px solid $white;
        border-radius: 100%;
        margin: 0 auto 0.8rem;
        overflow: hidden;
        position: relative;
    }

    &__image {
        height: 100%;
        object-fit: cover;
    }

    &__meta {
        box-shadow: 2px 4px 10px rgba($black, 0.1);
        flex: 1;
        padding: $spacing-l;
        @media (max-width: $break-tablet) {
            padding: 1.2rem;
        }

        @each $size,
            $values
                in (
                    small: (
                        1.6rem,
                        0.8rem
                    ),
                    medium: (
                        2.4rem,
                        1.2rem
                    ),
                    large: (
                        3.2rem,
                        1.6rem
                    )
                )
        {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: nth($values, 1);

                @media (max-width: $break-tablet) {
                    padding: nth($values, 2);
                }
            }
        }
    }

    &__meta-container {
        background: var(--player-list-card-background-colour);
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    &__forename {
        display: block;
        @include heading-XS;
        opacity: 0.7;
    }

    &__surname {
        display: block;
        @include heading-S;
    }

    &__position {
        display: block;
        @include text-misc;
        font-size: 1.2rem;
        font-weight: $regular;
        padding-top: 1.6rem;
    }

    &__brand-bar {
        background: var(--player-card-brand-bar-background);
    }

    @include hover {
        @include arrow-link-parent-hover;
        transform: scale(1.03);

        #{$this}__forename,
        #{$this}__surname {
            text-decoration: underline;
        }
    }

    @media (max-width: $break-phablet) {
        &__forename {
            font-size: var(--c-title-xsmall-mobile);
            line-height: 1.6rem;
        }

        &__surname {
            font-size: var(--c-title-small-mobile);
            line-height: 2rem;
        }

        &__position {
            font-size: 1rem;
            padding-top: 1.2rem;
        }

        &__image-container {
            height: 12.8rem;
        }

        &__brand-bar {
            height: 0.4rem;
        }
    }
}
