.clickable-image-block {
    --clickableImageBlock-backgroundColor: var(--g-widget-background);

    $this: &;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--clickableImageBlock-backgroundColor);

    &--inline {
        @include wrapper;

        // For inline always show outer spacing, regardless of config
        @include widget-outer-spacing;
    }

    &--full-width {
        // For full-width view, only apply outer spacing when widget spacing config has been set to true
        &#{$this}--with-spacing {
            @include widget-outer-spacing;
        }
    }

    &__image {
        display: block;
    }
}
