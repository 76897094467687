:root {
    --photoGrid-page-backgroundColor: var(--g-website-background);
    --photoGrid-root-backgroundColor: var(--g-color-system-light);
    --photoGrid-header-backgroundColor: var(--g-color-brand-secondary);
    --card-color: var(--g-widget-background);
    --photoGrid-text-color: var(--largeMediaPod-title-color);
    --photoGrid-title-color: var(--largeMediaPod-title-color);
    --photoGrid-tag-background-color: var(--g-color-brand-accent);
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.masonry-grid {
    $this: &;
    padding: 4.4rem;
    position: relative;
    background: var(--photoGrid-page-backgroundColor);

    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        visibility: hidden;
        grid-auto-rows: 1rem;
        gap: 2.4rem;
        @media (max-width: $break-phablet) {
            gap: 1.2rem;
            grid-auto-rows: auto;
        }

        @each $size,
            $values
                in (
                    extra-small: (
                        0.2rem,
                        0.1rem
                    ),
                    small: (
                        0.8rem,
                        0.4rem
                    ),
                    medium: (
                        1.6rem,
                        0.8rem
                    ),
                    large: (
                        2.4rem,
                        1.2rem
                    )
                )
        {
            body[data-theme-widget-card-outer-spacing='#{$size}'] & {
                gap: nth($values, 1);
                @media (max-width: $break-phablet) {
                    gap: nth($values, 2);
                }
            }
        }
    }

    &__container--loaded {
        visibility: visible;
    }

    &__placeholder {
        background: var(--photoGrid-text-color);
        pointer-events: none;
        width: 100%;
    }

    &__header {
        background: var(--photoGrid-header-backgroundColor);
        height: 48rem;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__wrapper {
        background: var(--photoGrid-root-backgroundColor);
        border-radius: var(--g-layout-border-radius);
        overflow: hidden;
        padding: 5.6rem;
        position: relative;
        z-index: $level-one;
    }

    &__item {
        background: var(--photoGrid-text-color);
        cursor: pointer;
        font-size: 0;
        position: relative;
        transition: 0.2s ease-in-out transform;
        overflow: hidden;

        &:hover {
            @include arrow-link-parent-hover;
            transform: scale(1.03);
            z-index: $level-one;

            #{$this}__expand-button {
                transform: scale(1.3);
            }
        }

        picture {
            pointer-events: none;
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
        width: 100%;
        pointer-events: none;
    }

    &__meta {
        max-width: 80rem;
        margin-inline: auto;
        padding-bottom: 4rem;
        text-align: center;
    }

    &__title {
        color: var(--photoGrid-title-color);
        @include heading-XL;
        margin-bottom: 1.6rem;
    }

    &__summary {
        color: var(--photoGrid-text-color);
        display: inline-block;
        @include text-copy;
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 2.4rem;
        max-width: 64rem;
    }

    &__size {
        align-items: center;
        background: var(--g-tags-background-color);
        color: var(--g-tags-text-color);
        display: inline-flex;
        @include text-misc;
        font-size: 1.2rem;
        font-weight: 800;
        margin-bottom: 2.4rem;
        padding: 0.8rem;
        gap: 0.5rem;
    }

    &__size-icon {
        display: block;
        fill: var(--g-tags-text-color);
        height: 1.5rem;
        width: 1.5rem;
    }

    &__tag {
        background: var(--g-tags-background-color);
        display: inline-block;
        color: var(--g-tags-text-color);
        font-size: 1.2rem;
        font-weight: 800;
        @include text-misc;
        margin: 0 0.4rem;
        padding: 0.6rem;
        text-transform: uppercase;
    }

    &__date {
        color: var(--photoGrid-text-color);
        opacity: 0.5;
        display: inline-block;
        font-size: 1.2rem;
        @include text-misc;
        margin: 0 0.4rem;
    }

    &__loader {
        animation: spin 1s linear infinite;
        display: block;
        height: 6rem;
        margin: 5rem auto;
        opacity: 0.2;
        width: 6rem;
    }

    &__expand-button {
        bottom: 1.1rem;
        cursor: pointer;
        height: 2rem;
        pointer-events: none;
        position: absolute;
        right: 1.1rem;
        transition: 0.1s ease-in-out transform;
        width: 2rem;
    }

    &__expand-icon {
        pointer-events: none;
        height: 100%;
        width: 100%;
    }

    @media (max-width: $break-phablet) {
        padding: 0.8rem;

        &__container {
            grid-template-columns: repeat(1, 1fr);
        }

        &__wrapper {
            padding: 0.8rem;
        }

        &__summary {
            font-size: 1.4rem;
        }

        &__size {
            font-size: 1rem;
        }

        &__tag {
            font-size: 1rem;
        }

        &__meta {
            padding: 2.4rem 0.8rem;
        }
    }
}
