// Styles for: Content listing
// Notes: Content listing widget style for all devices

// CONFIG

:root {
    --contentListing-root-backgroundColor: var(--g-widget-background);
    --contentListing-text-color: var(--g-typography-color-widget-component);
    --contentListing-brandBar-background: var(--brandBar-background);
    --contentListing-widgetHeaderCta-backgroundColor-default: var(
        --widgetHeader-cta-backgroundColor-default
    );
    --contentListing-widgetHeaderCta-backgroundColor-hover: var(
        --widgetHeader-cta-backgroundColor-hover
    );
    --contentListing-widgetHeaderCta-color-hover: var(
        --widgetHeader-ctaText-color-hover
    );
    --contentListing-mediaPod-boxShadow: var(--mediaPod-root-boxShadow);
    --contentListing-mediaPod-border: var(--mediaPod-root-border);
    --contentListing-mediaPodArrowLink-color: var(--mediaPod-arrowLink-color);
    --contentListing-mediaPod-backgroundColor: var(--g-component-background);
    --contentListing-mediaPodBrandBar-background: var(
        --mediaPod-brandBar-background
    );
    --contentListing-contentMetaTag-backgroundColor: var(
        --g-tags-background-color
    );
    --contentListing-contentMetaTag-color: var(--contentMeta-tag-color);
    --contentListing-loadMore-textcolor: var(--g-button-secondary-color);
    --contentListing-loadMore-background: var(--g-button-secondary-background);
    --contentListing-loadMore-bordercolor: var(
        --g-button-secondary-border-color
    );
}

.w-content-listing {
    position: relative;
    background-color: var(--contentListing-root-backgroundColor);

    &__load-more-container {
        display: flex;
        justify-content: center;
        margin-top: 3rem;

        .o-cta {
            min-width: 24rem;
            text-transform: uppercase;

            // Widget button theme overrides
            color: var(--contentListing-loadMore-textcolor);
            background: var(--contentListing-loadMore-background);
            border-color: var(--contentListing-loadMore-bordercolor);

            @include hover {
                color: var(--contentListing-loadMore-textcolor);
                background: var(--contentListing-loadMore-background);
                border-color: var(--contentListing-loadMore-bordercolor);
            }
        }
    }

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }

    &__loader {
        animation: spin 1s linear infinite;
        display: block;
        height: 6rem;
        margin: 5rem auto;
        opacity: 0.2;
        width: 6rem;
    }

    &__empty {
        color: rgba($black, 0.8);
        display: block;
        @include body-large;
    }

    // OVERRIDES FOR SHARED COMPONENTS

    // WIDGET HEADER

    .o-widget-header {
        &__title {
            color: var(--contentListing-widgetHeaderTitle-color);
        }

        &__brand-bar {
            background: var(--contentListing-brandBar-background);
        }
    }

    // MEDIA POD

    .o-media-pod {
        border: var(--contentListing-mediaPod-border);
        background-color: var(--contentListing-mediaPod-backgroundColor);
        box-shadow: var(--contentListing-mediaPod-boxShadow);

        &__brand-bar {
            background: var(--contentListing-mediaPodBrandBar-background);
        }

        &__heading {
            color: var(--contentListing-text-color);
        }

        &__summary {
            color: var(--contentListing-text-color);
        }
    }

    // CONTENT META
    .o-content-meta {
        &__item {
            &::before {
                background: var(--contentListing-text-color);
            }

            &--text {
                color: var(--contentListing-text-color);
            }

            &--tag {
                color: var(--contentListing-contentMetaTag-color);
                background: var(
                    --contentListing-contentMetaTag-backgroundColor
                );
            }
        }
    }
}
