// Styles for: Up next
// Notes: Up next widget style for all devices

// CONFIG

:root {
    --upNextItem-root-backgroundColor: var(--g-component-background);
    --upNextItem-root-boxShadow: var(--g-layout-box-shadow);
    --upNextItem-root-border: var(--c-border-none);
    --upNextItem-root-borderRadius: var(--g-layout-border-radius);
    --upNextItem-title-color: var(--g-color-system-dark);
    --upNextItem-summary-color: var(--g-color-system-dark);
    --upNextItem-arrowLink-color: var(--g-button-ghost-color);
}

// UP NEXT ITEM

.up-next-item {
    $this: &;
    display: flex;
    flex: 1 1 0;
    width: 100%;
    border: var(--upNextItem-root-border);
    border-radius: var(--upNextItem-root-borderRadius);
    overflow: hidden;
    background-color: var(--video-player-color);
    box-shadow: var(--upNextItem-root-boxShadow);
    transition: transform 0.15s ease-in-out;
    height: 100%;

    &__link {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-decoration: none;
    }

    &__image-container {
        &::after {
            display: block;
            padding-top: calc((10 / 16) * 100%);
            content: '';
        }
    }

    // VIDEO DURATION

    &__video-duration {
        z-index: $level-one;
        position: absolute;
        bottom: 1.6rem;
        left: 1.6rem;
    }

    &__video-play {
        z-index: $level-one;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 9rem;
        height: 9rem;
        border-radius: 50%;
        opacity: 0.95;
        background: var(--g-color-system-dark);
        transition: all 0.25s ease-in-out;
        transform: translate(-50%, -50%);
    }

    &__video-play-icn {
        fill: $white;
        position: absolute;
        width: 100%;
        height: 80%;
        margin: auto;
        inset: 0;
    }

    &__heading {
        @include heading-M;
        color: var(--video-player-text-color);
    }

    &__summary {
        display: block;
        @include body-small;
        color: var(--video-player-text-color);
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
    }

    &__date {
        color: var(--video-player-text-color);
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &__content {
        display: flex;
        flex: 1 0 0;
        flex-basis: auto;
        flex-direction: column;
        gap: 0.8rem;
        padding: $spacing-l;
        @media (max-width: $break-tablet) {
            padding: 1.6rem;
        }

        @each $size,
            $values
                in (
                    small: (
                        1.6rem,
                        0.8rem
                    ),
                    medium: (
                        2.4rem,
                        1.6rem
                    ),
                    large: (
                        3.2rem,
                        2.4rem
                    )
                )
        {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: nth($values, 1);

                @media (max-width: $break-tablet) {
                    padding: nth($values, 2);
                }
            }
        }
    }

    &__arrow-link-container {
        margin-top: auto;

        .o-arrow-link {
            margin-top: 1.6rem;
            color: var(--upNextItem-arrowLink-color);

            svg {
                fill: var(--upNextItem-arrowLink-color);
            }
        }
    }

    @include hover {
        @include arrow-link-parent-hover;
        transform: scale(1.03);

        #{$this}__heading {
            text-decoration: underline;
        }

        #{$this}__video-play {
            width: 10rem;
            height: 10rem;
            background: var(--video-play-btn-hover-color);
            box-shadow: var(--g-layout-box-shadow);
        }
    }
}
