@import 'classes-mixin';

:root {
    --stackedCarousel-backgroundColor: var(--g-widget-background);
    --stackedCarousel-title-color: var(--g-typography-color-widget-heading);
    --stackedCarousel-brandBar-backgroundColor: var(--g-color-gradient-1);
    --stackedCarousel-card-borderRadius: var(--g-layout-border-radius);
    --stackedCarousel-card-backgroundColor: var(--g-component-background);
    --stackedCarousel-card-text-color: var(
        --g-typography-color-widget-component
    );
}

.stacked-carousel {
    $this: &;
    background: var(--stackedCarousel-backgroundColor);
    overflow: hidden; // to prevent possible horizontal scroll

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 0.8rem;

        .o-widget-header {
            flex: 1;
            align-items: flex-start;

            &__title {
                color: var(--stackedCarousel-title-color);
            }

            &__brand-bar {
                background: var(--stackedCarousel-brandBar-backgroundColor);
            }
        }
    }

    &__controls {
        display: flex;
        gap: 0.8rem;

        // TODO: prev and next element will shortly be moved directly into header macro
        // this is a temporary fix
        margin-bottom: 4rem;
        margin-left: auto;
    }

    &__prev,
    &__next {
        .o-cta {
            display: inline-block;
            cursor: pointer;
            transition: opacity 0.2s, transform 0.2s, top 0.2s;
        }
    }

    &__prev {
        .o-cta {
            svg {
                transform: rotate(-180deg);
            }
        }
    }

    &__list {
        display: flex;
        transition: transform 0.8s;
        position: relative;
        height: $desktop-card-height;
        margin: 0 auto;

        &.no-animation {
            #{$this}__card,
            #{$this}__item {
                transition: none;
            }
        }

        &--no-card-image {
            height: $desktop-card-height-no-image;
            max-width: 80%;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        transition: all 0.6s;

        &.is-active,
        &.is-middle,
        &.is-bottom {
            opacity: 1;
        }

        &.is-active {
            pointer-events: all;
        }

        @include generate-animation-classes($animations-desktop, $this);
    }

    &__card {
        display: flex;
        height: 100%;
        flex: 1;
        overflow: hidden;
        position: relative;
        max-width: calc(100% - $offset-last-desktop);
        border-radius: var(--stackedCarousel-card-borderRadius);
        box-shadow: var(--c-box-shadow-default);
        transform-origin: left;
        transition: all 0.6s;

        &-content,
        &-media {
            display: flex;
            flex: 1;
        }

        &-media {
            height: 100%;
            width: 40%;
            overflow: hidden;
            #{$this}__card-image {
                transition: transform 0.3s;
            }
        }

        &-content {
            display: flex;
            flex-flow: column;
            gap: 0.8rem;
            padding: 2.4rem;
            background: var(--stackedCarousel-card-backgroundColor);
            color: var(--stackedCarousel-card-text-color);
            @media (min-width: $break-tablet) {
                padding: 1.6rem;
            }
            @each $size, $values in $inner-spacing {
                body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                    padding: nth($values, 1);
                    @media (max-width: $break-tablet) {
                        padding: nth($values, 2);
                    }
                }
            }

            .o-content-meta {
                &__item {
                    &::before {
                        background: var(--stackedCarousel-card-text-color);
                    }

                    &--text {
                        color: var(--stackedCarousel-card-text-color);
                    }
                }
            }
        }

        &-title {
            @include heading-S;
            transition: color 0.5s;
            @include truncate-multiline(2, 1.5);
        }

        &-description {
            font-size: var(--c-text-small-desktop);
            line-height: 1.5;
            @include truncate-multiline(6, 1.5);
        }

        &-cta {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: auto;
            position: relative;
        }

        &-share-container {
            .o-cta {
                max-width: 5rem;

                span {
                    display: none;
                }

                & svg {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }

        &-share {
            position: absolute;
            bottom: calc(100% + 1.6rem);
            right: 0;
            padding: 0.8rem;
            background: var(--g-button-primary-background);
            opacity: 1;
            pointer-events: all;
            transition: opacity 0.5s, transform 0.5s;
            border-radius: calc(var(--stackedCarousel-card-borderRadius) / 2);
            z-index: $level-one;

            &::before {
                content: '';
                position: absolute;
                bottom: -1.4rem;
                right: 1.4rem;
                border-left: 1.2rem solid transparent;
                border-right: 1.2rem solid transparent;
                border-top: 1.5rem solid var(--g-button-primary-background);
            }

            .w-share {
                &__buttons {
                    justify-content: space-around;
                    flex: 1;
                    gap: 0.8rem;
                }

                &__button {
                    width: 4rem;
                    height: 4rem;
                    padding: 0.8rem;
                    border-radius: calc(
                        var(--stackedCarousel-card-borderRadius) / 2
                    );

                    svg {
                        opacity: 1;
                        fill: var(--g-button-primary-color);
                    }

                    @include hover {
                        background: rgba($white, 0.2);
                    }
                }
            }

            &.is-hidden {
                opacity: 0;
                pointer-events: none;
                transform: translateY(-0.5rem);
            }
        }
    }

    &__reset {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        gap: 1.6rem;

        #{$this}__card-title {
            font-weight: $regular;
            font-size: var(--c-text-large-desktop);
            text-align: center;
            color: var(--stackedCarousel-title-color);
        }

        & .o-arrow-link {
            display: flex;

            span {
                order: 1;
            }

            svg {
                width: 1.4rem;
                height: 1.4rem;
            }
        }
    }

    @media (max-width: $break-tablet) {
        &__header,
        &__controls {
            gap: 0;
        }

        &__controls {
            margin-bottom: $spacing-m;
        }

        &__prev,
        &__next {
            .o-cta {
                display: none;
            }
        }

        &__list {
            height: $mobile-card-height;
            max-width: $mobile-card-max-width;

            &--no-card-image {
                height: $mobile-card-height-no-image;
            }
        }

        &__item {
            @include generate-animation-classes($animations-mobile, $this);
        }

        &__card {
            flex-direction: column;
            max-width: calc(100% - $offset-last-mobile);

            &-media {
                max-height: 20rem;
                min-height: 20rem;
                width: 100%;
            }

            &-description {
                font-size: var(--c-text-small-mobile);
                @include truncate-multiline(8, 1.5);
            }

            &-share {
                width: 100%;
            }
        }

        &__reset {
            #{$this}__card-title {
                font-size: var(--c-text-large-mobile);
            }
        }
    }
}
