:root {
    --additional-content-root-backgroundColor: var(--g-color-system-light);
    --additional-text-color: var(--g-typography-color-widget-component);
}

.additional-links {
    background: var(--additional-content-root-backgroundColor);
    padding: 5.6rem 0;

    // Clear pseudo elements on o-wrapper (clearfix) as they cause `gap` property to add gaps to the top and bottom
    .o-wrapper {
        &::after,
        &::before {
            content: none;
        }
    }

    &__grid {
        display: grid;
        gap: 5.6rem;
        grid-template-columns: repeat(4, 1fr);

        // Above tablet, set grid columns based on dynamic cols modifier
        @media (min-width: $break-tablet) {
            &--cols-4 {
                grid-template-columns: repeat(4, 1fr);
            }

            &--cols-3 {
                grid-template-columns: repeat(3, 1fr);
            }

            &--cols-2 {
                grid-template-columns: repeat(2, 1fr);
            }

            &--cols-1 {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    &__content {
        position: relative;
        height: 100%;
    }

    .lazy-image-wrapper {
        height: 100%;
        width: 100%;
        min-height: 22rem; // prevent images from collapsing
    }

    &__image {
        width: 100%;
    }

    // article content styling

    &__header,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--additional-text-color);
        display: block;
        @include heading-M;
        margin-bottom: 0.8rem;
    }

    a {
        color: var(--additional-text-color);
        display: block;
        font-size: 1.4rem;
        line-height: 2.8rem;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
    }

    &__text,
    p {
        color: var(--additional-text-color);
        display: block;
        @include text-copy;
        font-size: 1.4rem;
        line-height: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: $break-tablet) {
        &__grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: $break-phablet) {
        padding: 2.8rem 0;

        &__grid {
            grid-template-columns: repeat(1, 1fr);
            gap: 2.8rem;
        }

        &__link {
            line-height: 3.2rem;
        }
    }
}
