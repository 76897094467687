// Styles for: Article widget
// Notes: Article widget style for all devices

// CONFIG

:root {
    // This variable needs to be declared on root scope as progress bar is rendered outside
    // of the article element and therefore can't pick up any nested colour variables
    --article-progressBar-backgroundColor: var(--g-color-gradient-1);
}

$article-width: 144rem;
$article-header-width: 80rem;
$article-constrained-width: 64rem;

// ARTICLE

.w-article-page {
    $this: &;

    --article-root-backgroundColor: var(--g-website-background);
    --article-title-color: var(--g-typography-color-heading);
    --article-caption-color: var(--g-color-system-dark);
    --article-progressBar-backgroundColor: var(--g-color-gradient-1);
    --article-share-backgroundColor: var(--c-color-transparent);
    --article-copy-backgroundColor: var(--g-color-system-dark);
    --article-copy-color: var(--g-typography-color-paragraph);
    --article-text-color: var(--g-typography-color-paragraph);
    position: relative;
    background-color: var(--article-root-backgroundColor);

    /* text alignment styles */
    &--centre {
        .twitterThumb.left {
            justify-content: center;
        }
    }

    &--left {
        text-align: start;

        #{$this}__share,
        #{$this}__tags,
        #{$this}__meta {
            justify-content: flex-start;
        }

        #{$this}__heading,
        #{$this}__summary {
            justify-content: flex-start;
            text-align: start;
            margin-inline-start: 0;
        }

        .twitterThumb.center {
            justify-content: flex-start;
        }
    }

    /* lead media position */
    &--lead-media-above {
        display: flex;
        flex-direction: column;

        .full-width {
            margin: 0;
        }

        #{$this}__image {
            order: 1;

            &--above {
                padding-bottom: 0;
            }
        }

        #{$this}__header {
            order: 2;
        }

        #{$this}__wrapper {
            order: 3;
        }

        & .w-embeddable-photo--full-width {
            padding: 0;
        }
    }

    &--lead-media-below {
        #{$this}__header {
            padding-bottom: 0;
        }
    }

    &__header {
        padding: var(--g-style-widget-spacing) 0;
    }

    &__intro {
        max-width: calc($article-header-width + 3.2rem);
        padding: 0 1.6rem;
        text-align: center;
        margin: 0 auto;
    }

    &__heading {
        @include heading-XXL;
        margin-bottom: 4rem;
        color: var(--article-title-color);

        &--small {
            @include heading-M;
        }
    }

    &__summary {
        @include body-large;
        justify-content: center;
        display: flex;
        margin-inline: auto;
        max-width: 64rem;
        margin-bottom: 4rem;
        color: var(--article-text-color);
    }

    .o-content-meta {
        &__item {
            &::before {
                background: var(--article-text-color);
            }

            &--text {
                color: var(--article-text-color);
            }
        }
    }

    &__tags {
        display: flex;
        justify-content: center;
        margin-bottom: 0;

        /* stylelint-disable-next-line selector-max-universal */
        &:has(*) {
            margin-bottom: 4rem;
        }
    }

    &__meta {
        display: flex;
        justify-content: center;
        margin-bottom: 4rem;
        color: var(--article-text-color);
    }

    &__media {
        margin: 0 -1.2rem 4rem;
    }

    &__image-container {
        &::after {
            display: block;
            padding-top: calc((10 / 16) * 100%);
            content: '';
        }
    }

    &__caption {
        @include meta-2;
        opacity: 0.5;
        max-width: $article-constrained-width;
        margin: 0 auto;
        padding: 1.6rem 1.2rem;
    }

    // OVERRIDES

    .w-share {
        background-color: var(--article-share-backgroundColor);

        &__icon {
            fill: var(--article-text-color);
        }

        .copy__message {
            color: var(--g-color-system-light);
            background-color: var(--g-color-system-dark);

            &::after {
                border-top: 0.7rem solid var(--article-copy-backgroundColor);
            }
        }
    }

    .cms-content caption {
        color: var(--article-caption-color);
    }

    .articleWidget:first-of-type {
        margin-top: 0;
    }

    // CONTENT

    &__wrapper {
        max-width: $article-width;
    }

    &__content {
        position: relative;
        margin: 0 auto;
        @include text-copy;

        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6,
        > ul,
        > ol,
        > .table,
        > p,
        > blockquote,
        > pre,
        > table {
            max-width: $article-constrained-width;
            margin-right: auto;
            margin-left: auto;
        }

        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6,
        > ul,
        > ol,
        > p,
        > blockquote {
            color: var(--article-text-color);
        }

        > h1,
        > h2,
        > h3,
        > h4,
        > h5,
        > h6 {
            color: var(--article-title-color);
        }

        a:not(.accordion__title) {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    // ACCORDION

    .accordion {
        &__title {
            p {
                color: var(--article-title-color);
            }
        }

        &__icon {
            svg {
                fill: var(--article-title-color);
            }
        }

        /* stylelint-disable selector-max-type */
        &__body {
            p,
            div,
            ul li,
            ol li,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: var(--article-text-color);
            }
        }
        /* stylelint-enable selector-max-type */
    }
}

@media (max-width: $break-phablet) {
    .w-article-page {
        &__header {
            padding-top: $spacing-m;
            padding-bottom: $spacing-m;
        }

        &__summary {
            margin-bottom: $spacing-l;
            font-size: 1.8rem;
            line-height: 2.8rem;
        }

        &__tags {
            margin-bottom: 0;

            /* stylelint-disable-next-line selector-max-universal */
            &:has(*) {
                margin-bottom: 2.6rem;
            }
        }

        &__meta {
            margin-bottom: $spacing-l;
        }

        &__heading {
            margin-bottom: $spacing-l;
        }
    }
}

@media (max-width: $break-mobile) {
    .w-article-page {
        &__intro {
            padding: 0 $spacing-xs;
        }
    }
}
