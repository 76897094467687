:root {
    --player-profile-background-colour: var(--g-color-brand-secondary);
    --player-profile-text-background-colour: var(--c-color-white);
    --player-card-brand-bar-background: var(--g-color-gradient-1);
    --player-list-text-colour: var(--g-typography-color-widget-component);
    --player-profile-header-text-colour: var(--c-color-white);
    --player-profile-biography-text-colour: var(
        --g-typography-color-widget-component
    );
}

.player-profile {
    $this: &;
    background: var(--player-profile-text-background-colour);

    &__header {
        background: var(--player-profile-background-colour);
        color: var(--player-profile-header-text-colour);

        p {
            color: inherit;
        }
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
        min-height: 34.4rem;
    }

    &__player-image {
        display: flex;
        flex: 1;
        justify-content: center;
        padding-top: 5rem;
        font-size: 0;
    }

    &__image-container {
        overflow: hidden;
        position: relative;
        max-width: 28rem;
    }

    &__image {
        object-fit: cover;
    }

    &__image--rectangular {
        #{$this}__image-container {
            max-width: 35rem;
        }
    }

    &__image--rounded {
        #{$this}__image-container {
            aspect-ratio: 1/1;
            background: $color-dark;
            border: 0.5rem solid $white;
            border-radius: 100%;
            #{$this}__placeholder {
                fill: rgb($grey-400, 0.2);
                min-height: 31.2rem;
                max-width: 28rem;
            }
        }
    }

    &__content {
        font-size: 1.6rem;
        max-width: 67.2rem;
        padding: $spacing-xl 1.6rem;
        color: var(--player-profile-biography-text-colour);

        p {
            margin-bottom: 1rem;
        }
    }

    &__placeholder {
        min-height: 31.2rem;
        max-width: 28rem;
        fill: $color-dark;
    }

    &__details {
        max-width: 68rem;
        flex: 1;
        padding: 4rem 4.4rem;
    }

    &__name {
        @include heading-XXL;
    }

    &__description {
        @include text-copy;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    &__stats {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.8rem;
        font-weight: $extrabold;
        margin: 2rem -2rem;
    }

    &__stats-item {
        padding: 2rem;
        @include text-misc;
    }

    &__stats-label {
        display: block;
        font-size: 1.2rem;
        font-weight: $regular;
        line-height: 1.6rem;
    }

    &--show-compact {
        display: none;
    }

    &--hide-compact {
        display: hide;
    }

    &__read-more {
        color: var(--player-profile-biography-text-colour);
        display: box;
        -webkit-line-clamp: 8; /* number of lines to show */
        line-clamp: 8;
        -webkit-box-orient: vertical;
        box-orient: vertical; // stylelint-disable-line property-no-unknown
        outline: 0;
        overflow: hidden;
        position: relative;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        @include text-copy;
    }

    &__read-more--active {
        -webkit-line-clamp: unset;
        line-clamp: unset;
        max-height: 1000rem;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }

    &__read-more-btn {
        background: var(--player-profile-background-colour);
        bottom: 0;
        color: $white;
        display: flex;
        font-weight: $extrabold;
        left: 50%;
        line-height: 2rem;
        outline: 0;
        padding: 1.4rem 1.6rem;
        position: absolute;
        z-index: $level-one;
        transform: translateX(-50%);
    }

    &__read-more-icon {
        display: inline-block;
        fill: $white;
        margin-left: 1rem;
    }

    &__read-more-icon--active {
        transform: rotate(180deg);
    }

    &__read-more-btn--active {
        left: 0;
        margin: 0 auto;
        position: relative;
        right: 0;
        transform: none;
    }

    &__biog-title {
        display: block;
        @include heading-S;
        margin-bottom: 1rem;
    }

    @media (max-width: 750px) {
        &__wrapper {
            flex-direction: column;
            padding: 4rem;
        }

        &__details {
            padding: 0;
            max-width: none;
        }

        &__player-image {
            margin: 0 auto 4rem;
            min-width: auto;
            padding: 0;

            &--rounded {
                margin: 0 auto;
            }
        }

        &__image-rounded-container,
        &__image {
            height: 23rem;
            width: 23rem;
        }

        &--show-compact {
            display: block;
        }

        &--hide-compact {
            display: none;
        }
    }

    @media (max-width: $break-phablet) {
        &__wrapper {
            padding: 4rem 1.6rem;
        }

        &__stats {
            margin: 0 -1.6rem;
        }

        &__player-image {
            margin: 3rem auto;
        }

        &__stats-item {
            font-size: 1.2rem;
            padding: 1.6rem;
        }

        &__stats-label {
            font-size: 1rem;
        }

        &__image-rounded-container {
            margin: 0;
        }

        &__description {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
}
