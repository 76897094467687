:root {
    --promo-grid-background-color: transparent;
    --promo-grid-text-color: var(--g-typography-color-widget-component);
    --promo-grid-widgetHeaderTitle-color: var(
        --g-typography-color-widget-heading
    );
    --promo-grid-brandBar-background: var(--brandBar-background);
}

.simple-promo-listing {
    $this: &;
    background: var(--promo-grid-background-color);
    overflow: hidden;

    &__promo-container {
        position: relative;
        text-align: center;
        width: 100%;
    }

    &__image-container {
        background: $black;
        border-radius: var(--g-layout-border-radius);
        box-shadow: var(--g-layout-box-shadow);
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        width: 100%;
    }

    &__image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        &--square {
            padding-top: 100%;
        }
    }

    &__label-container {
        &--overlay {
            left: 0;
            margin: 0 auto;
            padding: 0 1.2rem;
            position: absolute;
            right: 0;
            top: 50%;
            text-align: center;
            transform: translateY(-50%);
        }

        &--bottom {
            @extend .simple-promo-listing__label-container--overlay; // stylelint-disable-line scss/at-extend-no-missing-placeholder
            bottom: 0;
            padding: 0;
            top: auto;
            transform: none;
            width: 100%;

            #{$this}__label {
                margin-bottom: 0;

                &--has-bg {
                    width: calc(100% - 0.1rem);
                    border-bottom-right-radius: var(--g-layout-border-radius);
                    border-bottom-left-radius: var(--g-layout-border-radius);
                }
            }
        }
    }

    &__label {
        display: block;
        color: var(--promo-grid-text-color);
        @include heading-XS;
        margin: 0.8rem 0;

        &--overlay {
            background: $black;
            display: inline-block;
            padding: 1.2rem 1.6rem;
        }

        &--has-bg {
            background: rgba($black, 0.7);
            backdrop-filter: blur(10px);
            padding: 0.4rem 0.6rem;
        }
    }

    &__link {
        color: initial;
        display: block;
        text-decoration: none;
        transition: 0.15s ease-in-out transform;
        width: 100%;

        &:hover {
            @include arrow-link-parent-hover;
            transform: scale(1.03);

            #{$this}__label {
                text-decoration: underline;
            }
        }
    }

    .o-widget-header {
        &__title {
            color: var(--promo-grid-widgetHeaderTitle-color);
        }

        &__brand-bar {
            background: var(--promo-grid-brandBar-background);
        }
    }
}
