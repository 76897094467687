:root {
    --featured-widget-backgroundColor: var(--g-widget-background);
    --featured-widget-paddingBottom: var(--g-layout-widget-spacing);
    --featured-card-color: var(--g-color-system-light);
    --featured-text-color: var(--g-typography-color-widget-component);
    --related-item-text-color: var(--g-typography-color-widget-component);
    --featured-widget-button-color: var(--g-button-ghost-color);
}

.featured-article,
.featured-video,
.featured-gallery {
    $this: &;
    background: var(--featured-widget-backgroundColor);
    padding-bottom: var(--featured-widget-paddingBottom);
    position: relative;

    &__image-container {
        display: block;
        height: 55rem;
        position: relative;
        transition: 0.2s ease-in-out transform;
    }

    &__single-image-container {
        transition: 0.2s ease-in-out transform;
    }

    &__image {
        object-position: top;
        width: 100%;
    }

    &__gallery-image {
        border: 0.1rem solid $white;
        border-top: none;
        flex: 1;
        position: relative;
    }

    &__title {
        color: var(--featured-text-color);
        @include heading-L;
    }

    &__summary {
        @include text-copy;
        color: var(--featured-text-color);
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    .o-arrow-link {
        color: var(--featured-widget-button-color);
        margin-top: 1.6rem; // combines with gap to create 2.4rem spacing

        svg {
            fill: var(--featured-widget-button-color);
        }
    }

    &__main {
        color: initial;
        padding-inline-end: 3.4rem;
        max-width: 80%;
        text-decoration: none;
        gap: 0.8rem;
        display: grid;

        &:hover {
            @include arrow-link-parent-hover;

            .featured-video,
            .featured-article,
            .featured-gallery {
                &__title {
                    text-decoration: underline;
                }
            }
        }
    }

    &__meta {
        align-items: center;
        background: var(--featured-card-color);
        border-radius: var(--g-layout-border-radius);
        color: var(--featured-text-color);
        display: flex;
        overflow: hidden;
        margin-top: -11.5rem;
        max-width: 135.2rem;
        min-height: 15rem;
        position: relative;
        width: 98%;
        z-index: $level-one;
        box-shadow: var(--c-box-shadow-default);
        padding: $spacing-xl;
        @media (max-width: $break-tablet) {
            padding: 0.8rem;
        }
        @media (min-width: $break-tablet) {
            @each $size, $value in (small: 2.4rem, medium: 3.2rem, large: 4rem)
            {
                body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                    padding: $value;
                }
            }
        }
    }

    &__related {
        border-inline-start: 0.2rem solid rgba($black, 0.1); // rtl change
        margin-inline-start: auto;
        padding-inline-start: 3.4rem;
        width: 32rem;
    }

    &__related-item {
        align-items: center;
        color: var(--related-item-text-color);
        font-size: 1.2rem;
        display: flex;
        max-width: 32rem;
        padding: 0.4rem 0;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__related-item-text {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
    }

    &__related-title {
        color: var(--related-item-text-color);
        display: block;
        @include heading-S;
        margin-bottom: 0.8rem;
    }

    &__related-icon {
        display: inline-block;
        fill: var(--related-item-text-color);
        flex-shrink: 0;
        height: 1.2rem;
        margin-inline-end: 1rem;
        width: 1.2rem;
    }

    &__play-icon {
        height: 12rem;
        left: 50%;
        position: absolute;
        top: 18.4rem;
        transform: translateX(-50%);
        transform-origin: left;
        transition: 0.3s ease-in-out transform;
        width: 12rem;
        z-index: $level-one;
        background: rgba($black, 0.7);
        fill: $white;
        border-radius: 10rem;
    }

    &__link-container {
        display: block;
        overflow: hidden;

        &:hover {
            .featured-article__image-container,
            .featured-video__image-container,
            .featured-gallery__single-image-container {
                transform: scale(1.05);
            }

            .featured-video__play-icon {
                transform: scale(1.1) translateX(-50%);
            }
        }
    }

    &__multi-image-container {
        display: flex;
        overflow: hidden;
    }

    @media (max-width: $break-tablet) {
        &__meta {
            background: transparent;
            display: block;
            margin-top: -8rem;
            overflow: visible;
            box-shadow: none;
        }

        &__main {
            background: var(--featured-card-color);
            display: block;
            max-width: none;
            box-shadow: var(--g-layout-box-shadow);
            @each $size,
                $values in (small: 1.6rem, medium: 2.4rem, large: 3.2rem)
            {
                body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                    padding: $values;
                }
            }
        }

        &__related {
            border: 0;
            margin: 0;
            padding: 2.4rem 0.8rem 0;
            width: auto;
        }

        &__related-item {
            padding: 0.8rem 0;
        }

        &__play-icon {
            height: 8rem;
            top: 12.4rem;
            width: 8rem;
        }
    }

    @media (max-width: $break-phablet) {
        &__title {
            line-height: 2.8rem;
        }

        &__image-container {
            flex-wrap: wrap;
            height: auto;
        }

        &__gallery-image {
            flex: none;
            height: 20rem;
            width: 50%;

            &--single-thumbnail {
                height: 40rem;
                width: 100%;
            }
        }

        &__summary {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
}

.featured-video__image-container,
.featured-article__image-container {
    @media (max-width: $break-phablet) {
        height: 40rem;
    }
}
