// Styles for: Slim Promo widget
// Notes: Promo listing widget style for all devices

.w-promo-slim {
    $this: &;

    --promoSlim-widget-backgroundColor: var(--g-color-system-light);
    --promoSlim-card-backgroundColor: var(--g-widget-background);
    --promoSlim-text-color: var(--g-typography-color-widget-component);
    --promoSlim-brandBar-background: var(--g-color-gradient-1);
    --promoSlim-card-boxShadow: var(--g-layout-box-shadow);
    --promoSlim-button-color: var(--g-typography-color-widget-component);
    background-color: var(--promoSlim-widget-backgroundColor);

    .o-widget-card {
        &__container {
            background: var(--promoSlim-card-backgroundColor);
        }

        &__brand-bar {
            background: var(--promoSlim-brandBar-background);
        }
    }

    &--center {
        #{$this}__content,
        #{$this}__text-content {
            flex-direction: column;
        }

        #{$this}__text {
            align-items: center;
            text-align: center;
        }

        #{$this}__cta-container {
            margin-left: 0;
        }
    }

    &__content {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        flex-wrap: wrap;
        padding: 1.6rem;
    }

    &__text-content {
        display: flex;
        align-items: center;
        gap: 1.2rem;
    }

    &__icon {
        display: flex;
        align-items: center;

        svg {
            width: 3rem;
            height: 3rem;
            fill: var(--promoSlim-text-color);
        }
    }

    &__image {
        position: relative;
        width: 8rem;
        height: 8rem;
        min-width: 8rem;
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        max-width: 80rem;
        color: var(--promoSlim-text-color);
    }

    &__title {
        color: var(--promoSlim-text-color);
        padding-bottom: 0.5rem;
        @include heading-M;
    }

    &__description {
        @include text-copy;
        font-size: var(--c-title-xsmall-mobile);
        line-height: 1.5;
    }

    &__cta-container {
        margin-inline-start: auto;
    }

    .o-arrow-link {
        color: var(--promoSlim-button-color);

        svg {
            fill: var(--promoSlim-button-color);
        }
    }

    @media (min-width: $break-phablet) {
        &__content {
            padding-left: 3.2rem;
            padding-right: 3.2rem;
        }

        &__content,
        &__text-content {
            gap: 2.4rem;
        }

        &__description {
            font-size: var(--c-title-xsmall-desktop);
        }
    }
}
