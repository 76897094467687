// Styles for: Page Header
// Notes: Page header is a widget for all devices
// Displayed with a heading (h1), and sub heading (h2) with an option image as a
// background

:root {
    --page-header-overlay: var(--g-color-brand-secondary);
    --page-header-text: var(--c-color-white);
    --page-header-button: var(--g-button-primary-background);
    --page-header-nav: var(--g-color-system-light);
    --page-header-brand-bar: var(--g-color-gradient-1);
}

.page-title {
    $this: &;
    background-position: center;
    background-size: cover;
    color: var(--page-header-text);
    position: relative;

    &--center {
        #{$this}__content {
            max-width: 80rem;
            margin: 0 auto;
            text-align: center;
        }
        #{$this}__nav-container {
            text-align: center;
        }
    }

    &--floating-nav {
        margin: 0;
    }

    &--left {
        #{$this}__wrapper {
            padding: 0 4.4rem;
        }

        #{$this}__content {
            align-items: flex-start;
        }
    }

    &__heading {
        @include heading-XL;
        text-align: center;
    }

    &__subtitle {
        @include text-copy;
        font-size: 1.6rem;
        font-weight: $regular;
    }

    .o-cta {
        margin-top: 2.4rem;
    }

    &__image {
        object-position: center;
    }

    // The main content container, works alongside the "overlay" (created below)

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: $spacing-m 0;
        position: relative;
        width: 100%;
        z-index: $level-one;
        padding: 5.6rem;
        @media (max-width: $break-tablet) {
            padding: 2.4rem;
        }

        @each $size,
            $value
                in (
                    small: (
                        3.2rem,
                        1.6rem
                    ),
                    medium: (
                        5.6rem,
                        2.4rem
                    ),
                    large: (
                        8rem,
                        3.2rem
                    )
                )
        {
            body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                padding: nth($value, 1);

                @media (max-width: $break-tablet) {
                    padding: nth($value, 2);
                }
            }
        }

        &--floating {
            /*
            * When the widget is set to floating and the nav is in absolute position, 
            * the nav is too close to the elements above it, so it needs extra space.
            */
            $space-bottom: 2.5rem;

            @each $size,
                $value
                    in (
                        small: (
                            3.2rem,
                            1.6rem
                        ),
                        medium: (
                            5.6rem,
                            2.4rem
                        ),
                        large: (
                            8rem,
                            3.2rem
                        )
                    )
            {
                body[data-theme-widget-card-inner-spacing='#{$size}'] & {
                    /*
                    * Calculate the total padding + half the height of the nav witch is 2.5rem
                    */
                    padding-bottom: calc(nth($value, 1) + $space-bottom);

                    @media (max-width: $break-tablet) {
                        padding-bottom: calc(nth($value, 2) + $space-bottom);
                    }
                }
            }
            padding-bottom: calc(5.6rem + $space-bottom);
            @media (max-width: $break-tablet) {
                padding-bottom: calc(2.4rem + $space-bottom);
            }
        }
    }

    // Overlay adds a blue semi transparent window ontop of the background image

    &__overlay {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: $level-one;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                to right,
                var(--page-header-overlay) 0%,
                var(--page-header-overlay) 20%,
                transparent 90%
            );
        }

        &::after {
            left: auto;
            right: 0;
            background: linear-gradient(
                to left,
                var(--page-header-overlay) 0%,
                var(--page-header-overlay) 20%,
                transparent 90%
            );
        }

        &--no-image {
            background: var(--page-header-overlay);

            &::before,
            &::after {
                display: none;
            }
        }
    }

    &__brand-bar {
        background: var(--page-header-brand-bar);
        display: block;
        position: relative;
        width: 100%;
        z-index: $level-two;
    }

    &__nav-container {
        bottom: 0;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        transform: translateY(50%);
        z-index: $level-three;

        &--full-width {
            position: relative;
            transform: none;
        }

        &--floating {
            @include wrapper;
            padding: 0 4.4rem;
        }
    }

    @media (max-width: $break-phablet) {
        &--left {
            #{$this}__wrapper {
                padding: 0 1.6rem;
            }
        }

        &__subtitle {
            font-size: 1.4rem;
        }

        .o-cta {
            margin-top: 1.6rem;
        }

        &__nav-container {
            &--floating {
                padding: 0 1.6rem;
            }
        }

        &--floating-nav {
            margin-bottom: 1.6rem;
        }

        &__overlay {
            &::before,
            &::after {
                width: 100%;
            }
        }

        &__content {
            gap: $spacing-xs 0;
        }
    }
}
