// Styles for: cms content
// Notes: cms content style for all devices
// stylelint-disable selector-max-specificity, selector-max-type, selector-no-qualifying-type

// CONFIG

:root {
    --article-text-color: var(--g-color-system-dark);
}

// CMS CONTENT

.cms-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: $spacing-xs;
        color: var(--article-text-color);

        a {
            color: var(--g-color-brand-primary);
            text-decoration: none;

            @include hover {
                text-decoration: underline;
            }
        }
    }

    h1 {
        @include cms-heading-1;
    }

    h2 {
        @include cms-heading-2;
    }

    h3 {
        @include cms-heading-3;
    }

    h4 {
        @include cms-heading-4;
    }

    h5 {
        @include cms-heading-5;
    }

    h6 {
        @include cms-heading-6;
    }

    > p:first-of-type + ul,
    > p:first-of-type + ol {
        margin-top: 6rem;
    }

    p {
        @include cms-paragraph;
        color: var(--article-text-color);
        margin-bottom: $spacing-l;
    }

    .cta {
        margin-bottom: 1.2rem;

        @include hover {
            text-decoration: none;
        }
    }

    ul,
    ol {
        @include cms-list;
        color: var(--article-text-color);
        width: auto;
        text-align: left;

        &[type='1'] {
            li {
                list-style: decimal;
            }
        }
        
        &[type='a'] {
            li {
                list-style: lower-alpha;
            }
        }
    }

    ul {
        margin: 0 0 3rem;
        padding: 0 0 0 1.8rem;

        li {
            position: relative;
            margin: $spacing-xxs 0;
            padding: 0 0 0 0.6em;
            list-style-type: disc;

            a {
                text-decoration: none;
                color: var(--g-color-brand-primary);
                font-weight: $bold;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }

    ol {
        margin: 0 0 3rem;
        padding: 0 0 0 1.8rem;

        li {
            margin: $spacing-xxs 0;
            padding: 0 0 0 0.6em;
            list-style-type: decimal;

            a {
                text-decoration: none;
                color: var(--g-color-brand-primary);
                font-weight: $bold;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }

    dl {
        margin-bottom: 1.2rem;
        margin-top: 0;
        font-size: 1.8rem;
    }

    dt,
    dd {
        line-height: 1.4;
    }

    dd {
        margin-bottom: 1rem;
        margin-left: 0;
    }

    img {
        max-width: 100%;

        &.left {
            padding: 0.5rem 2rem 1.2rem 0;
            vertical-align: top;
            float: left;
        }

        &.right {
            padding: 0.5rem 0 1.2rem 2rem;
            float: right;
        }
    }

    blockquote,
    q {
        quotes: none;
    }

    blockquote::before,
    blockquote::after,
    q::before,
    q::after {
        content: none;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: $bold;
    }

    pre,
    code {
        padding: 0.5rem;
        margin: 0;
        border-radius: 0.3rem;
        font-size: 1.4rem;
        line-height: 1.2;
        color: $black;
        word-break: break-all;
        word-wrap: break-word;
        white-space: pre-wrap;
        background-color: $grey-300;
        border: 0.1rem solid $grey-600;
        display: block;
    }

    pre {
        margin-bottom: 3rem;
    }

    hr {
        margin: 3rem 0;
        border: none;
        border-bottom: 0.1rem solid $color-mid;
    }

    address {
        margin: 0 0 1.2rem;
        font-size: 1.6rem;
        line-height: 1.4;
    }

    // Alignment of tags

    audio,
    canvas,
    iframe,
    img,
    svg,
    video {
        vertical-align: middle;
    }
}
